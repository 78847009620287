import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../components/common/not-found';
import PrivateRoutes from './private-routes';
import ProtectedRoute from './protected-routes';
import routes from './routes';
import TherapistDetailStaticPage from '../pages/callSchedule/therapistDetailsStatic.page';
import ProgramsLandingPage from '../pages/landing/programs';
import { MixPanelEvents } from '../helpers/constant';
import mixpanel from 'mixpanel-browser';
import ScheduleCallOfflinePage from '../pages/callSchedule/scheduleCallOffline';
import TherapistSelectionPage from '../pages/callSchedule/therapistSelection.page';
import OfflineDownloadApp from '../pages/enroll-program/download_app_offline';
import EnrollInProgram from '../pages/enroll-program';
import ServiceSelectionPage from '../pages/callSchedule/serviceSelection.page';

export interface IRoutesProps {
  path: string;
  element: React.ReactNode;
  children?: IRoutesProps[];
}

const RoutesComp: React.FC = () => {
  const childrenRoutes = (routes: IRoutesProps[]) => {
    return routes?.map((route, index) => {
      if (route.children)
        return (
          <Route path={route.path} element={route.element} key={index}>
            {childrenRoutes(route?.children)}
          </Route>
        );
      return <Route key={index} path={route.path} element={route.element} />;
    });
  };

  useEffect(
    ()=>{
      try {
        mixpanel.track(MixPanelEvents.landing, {
          isWebFlow: true,
        });
      } catch (error) {
        //ignore
      }
    },[]
  )

  return (
    
    
    <Routes>
      <Route path='getapp' element={<EnrollInProgram />} />
      <Route element={<PrivateRoutes />}>{childrenRoutes(routes.private)}</Route>
      <Route element={<ProtectedRoute />}>{childrenRoutes(routes.protected)}</Route>
      <Route path='therapist' element={<TherapistDetailStaticPage/>} />
      <Route path='offline/callbook/:id' element={<ScheduleCallOfflinePage isPsych={false}/>} />
      <Route path='offline/callbook/psychiatrist' element={<ScheduleCallOfflinePage isPsych={true}/>} />
      <Route path='offline/services' element={<ServiceSelectionPage/>} />
      <Route path='offline/therapists' element={<TherapistSelectionPage/>} />
      <Route path="*" element={<NotFound />} />
      <Route path='landing/programs' element={<ProgramsLandingPage/>} />
    </Routes>
    
  );
};

export default RoutesComp;
