import { useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import MitsuLogo from '../../assets/icons/Mitsu';
import MitsuLogoMobile from '../../assets/icons/MitsuLogoMobile';
import { AdaptiveTypography, Button, Typography } from '../../components/base/index';
import { Seo } from '../../components/common';
import BoxContainer from '../../components/common/box-container/index';
import StepCard from '../../components/common/steps-card/index';
import { OnBoardingData } from '../../helpers/constant';
import { AppContext } from '../../helpers/hooks/AppContext';
import notify from '../../helpers/toastify-helper';
import { getUserDetails } from '../../services/selfcheckin.service';
import { getUserSteps, postCompletedStep } from '../../services/users.service';
import css from './index.module.scss';
import { hideIntroVideo } from '../../helpers/abTest';
import { useSearchParam } from 'react-use';
import MitsuComparisonTable from '../callSchedule/mitsuComparisonTable';
import { CLIENT_ROUTES } from '../../router/routes';
import { getPreviousTimeSlot } from '../../services/callSchedule.service';
import { ClockIcon } from '@mui/x-date-pickers';
import moment from 'moment';

const OnBoardingDashboard: React.FC = () => {
  const [step, setStep] = React.useState<number>(0);
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const { appDispatch, mixpanel } = useContext(AppContext);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const query = useSearchParam('hideIntroVideo');

  const hideIntro = query ?? hideIntroVideo();

  const shouldHideIntro = hideIntro == 'B'



  const userSteps = async () => {
    const response: any = await getUserSteps(userId as string);
    if (response?.data) {
      const { data } = response;
      appDispatch({
        type: 'setStepsData',
        payload: {
          stepsData: {
            stepsCompleted: data.stepsCompleted,
          },
        },
      });
      setStep(data.stepsCompleted);
      if( shouldHideIntro &&  data.stepsCompleted == 0){
        const completedStep = { stepsCompleted: 1 };
        const response: any = await postCompletedStep(userId, completedStep);
        userSteps()
      }
    } else if (response.statusCode === 401) {
      notify({
        title: 'Service unavailable',
        message: response.message,
        severity: 'warning',
        dismissible: false,
        showIcon: false,
      });
    } else {
      notify({ message: 'Unable to get data', severity: 'error' });
    }
  };

  useEffect(() => {
    userSteps();
    setEmailVerificationStatus();

    try {
      mixpanel.people.set('hide_intro_abtest', hideIntro)    

    } catch (error) {
      //ignore      
    }
  }, []);

  const setEmailVerificationStatus = async () => {
    const details = await getUserDetails();
    if (details.isEmailVerified) {
      localStorage.setItem('emailVerified', 'true');
    }
    if(details.isTrialPeriod){
      setPaymentStatus('trial')
    }
    if(details.isPaymentCompleted){
      setPaymentStatus('program')
    }
    console.log({details})
  };

  const [paymentStatus, setPaymentStatus] = useState('')

  const [previousSlot, setPreviousSlot] = useState(null)
  const getAlreadySelectedSlot = async () => {
    try {
      const slot = await getPreviousTimeSlot();
      setPreviousSlot(slot);
    } catch (error) {
      //ignore
    }
  };

  useEffect(
    ()=>{
      getAlreadySelectedSlot()
    },[]
  )

  const goToNextStep = () => {

    if((previousSlot as any)?.isCallCompleted){
      navigate('/onboarding/enroll-in-program')

    }else{
      onStepClicked(activeSteps>3?3:activeSteps)
    }

    // navigate(`${OnBoardingData.at(step)?.link}`);
  };

  const onStepClicked  = (clickedStep:number) => {
    
    if(clickedStep<2) {
      if(clickedStep < step) {
        navigate(OnBoardingData.at(clickedStep)?.reviewLink ??OnBoardingData.at(clickedStep)?.link! )
      }else{
        navigate(OnBoardingData.at(clickedStep)?.link! )
      }
    }
    if(clickedStep == 2){
      if(paymentStatus == 'trial'){
        navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.trial_page}` )
      }else if (paymentStatus == 'program'){
        navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.program_page}` )
      }else{
        navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.plan_selection}` )
      }
    }
    if(clickedStep == 3){
      if(!previousSlot){
        navigate(`/onboarding/setup_free_call/call_explainer` )
      }
      else if(!(previousSlot as any)?.isCallCompleted){
        navigate(`/onboarding/setup_free_call/get_to_know` )
      }else{
        navigate(`/onboarding/setup_free_call/get_to_know` )
      }
    }
  }
  let activeSteps = step
  if(paymentStatus){
    activeSteps +=1;
  }

  console.log({previousSlot})

  return (
    <React.Fragment>
      <Seo title={'Dashboard'} />
      <BoxContainer
        className={css.container}
        title={
          <Typography variant="h1" className={css.mobileLeft}>
            Welcome to {isMobileView ? <MitsuLogoMobile /> : <MitsuLogo />}
          </Typography>
        }
        subheader={
          <Typography variant="h5" className={css.mobileLeft}>
            You&apos;re on your way to better mental health.
          </Typography>
        }
      >
        <div className={css.dashboardContainer}>
          <div className={css.cardContainer}>
            {OnBoardingData.map((item: any, index: number) => {
              const stepNum = shouldHideIntro? index: index + 1;

              let activeSteps = step
              if(paymentStatus){
                activeSteps +=1;
              }
              return (
                <StepCard
                  key={index}
                  variant={index <= activeSteps ? 'enabled' : 'disabled'}
                  stepDescription={item.description}
                  status={index < activeSteps}
                  stepNumber={stepNum}
                  onButtonClick={() =>
                    onStepClicked(index)
                  }
                  isContinue = {index==3 && activeSteps>=3 && (!(previousSlot as any)?.isCallCompleted)}
                />
              );
            }).filter((item,i)=> shouldHideIntro? i>0: true)}
          </div>
          
          <div className={css.nextStepButton}>
            {!!previousSlot && !(previousSlot as any).isCallCompleted && 
              <div className={css.call_timing}>
                <ClockIcon/>
                <AdaptiveTypography> Your appointment is scheduled for {moment((previousSlot as any).previousStart).format('MMMM DD, yyyy')} at {moment((previousSlot as any).previousStart).format('h:mm A')}  </AdaptiveTypography>
              </div>
            }
            <Button onClick={goToNextStep} className={css.button}>
              Go to next step
            </Button>
          </div>
        </div>
      </BoxContainer>
      <Outlet />
    </React.Fragment>
  );
};

export default OnBoardingDashboard;
