import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { Typography } from '../../components/base';
import Button from '../../components/base/button';
import { Seo } from '../../components/common';
import BoxContainer from '../../components/common/box-container';
import { AppContext } from '../../helpers/hooks/AppContext';
import useBeforeUnloadAndNavigate from '../../helpers/hooks/useBeforeUnloadAndNavigate';
import notify from '../../helpers/toastify-helper';
import { ILoginStates } from '../../helpers/types/login.types';
import { getOtpConfirmation, getOtpVerification, sendUserSignupDetails, trackConversion } from '../../services/register.service';
import css from './index.module.scss';
import OtpInput from './login-container';
import { MixPanelEvents } from '../../helpers/constant';

const Login = () => {
  const { appDispatch, navigate, mixpanel } = useContext(AppContext);
  const mobileNumber = localStorage.getItem('mobile');
  const [loginStates, setLoginStates] = useState<ILoginStates>({
    value: '',
    loading: false,
    isTimer: true,
    error: false,
  });

  const { value, loading, isTimer, error } = loginStates;

  const expiryTimestamp = moment().add(1, 'minute').toDate(); // 1min

  const { seconds, minutes, start, pause, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => setLoginStates((prevState) => ({ ...prevState, isTimer: !prevState.isTimer })),
  });

  useEffect(() => {
    start();
  }, []);

  const handleOtpConfirmation = async () => {
    pause();
    const postData = { code: value, mobile: mobileNumber };
    setLoginStates((prevState) => ({ ...prevState, loading: !prevState.loading }));
    const response = await getOtpVerification(postData);
    setLoginStates((prevState) => ({ ...prevState, loading: !prevState.loading }));
    try {
      if (response?.statusCode === 201) {
        try {
          trackConversion('OTP_Verified_Event')
        } catch (error) {
          
        }
        try {
            mixpanel.track(MixPanelEvents.otpVerified, {
                isWebFlow: true,
                'sourceURL': sessionStorage.getItem('source_url') || window.location.href,
            });
        } catch (error) {
            //
        }
        sendUserSignupDetails({
          mobile: mobileNumber,
          utm: localStorage.getItem('initial_landing_url') ?? ''
        })
        try {
          (window as any).fbq('trackCustom', 'OTP_Verified_Event')
        } catch (error) {
          console.log('Cannot send custom pixel')
        }
        if (response?.data?.isMobileVerified) {
          const { data } = response;
          localStorage.setItem('accessToken', data?.access_token);
          localStorage.setItem('userId', data?.id);
          localStorage.setItem('email', data?.email);
          localStorage.setItem('name', data?.name);
          
          // mixpanel.identify(data?.id);
  
          if(localStorage.getItem('websitemp_id')){
            mixpanel.identify(localStorage.getItem('websitemp_id'));
            mixpanel.alias(data?.id, localStorage.getItem('websitemp_id'));
          }else{
            mixpanel.identify(data?.id);
          }
  
          let campaign = localStorage.getItem('utm_campaign_custom')
          let campaign_url = localStorage.getItem('utm_campaign_custom_url')
  
          if (campaign != null) {
            mixpanel.people.set('utm_campaign_custom', campaign)
            mixpanel.people.set('utm_campaign_custom_url', campaign_url)
  
          }
          if (localStorage.getItem('initial_landing_url')){
            mixpanel.people.set('initial_landing_url', localStorage.getItem('initial_landing_url'))
          }
          appDispatch({
            type: 'setUserData',
            payload: {
              ...data,
            },
          });
          if (sessionStorage.getItem('initialRoute')?.includes('onboarding/')) {
            navigate('onboarding' + sessionStorage.getItem('initialRoute')?.split('onboarding').at(-1));
          } else {
            navigate('onboarding/dashboard');
          }
        } else {
          navigate('personal-details');
        }
      } else {
        try {
          mixpanel.track(MixPanelEvents.otpFailed, {
                  isWebFlow: true,
              });
          } catch (error) {
              //
          }
        notify({ message: response.message, severity: 'error', dismissible: true });
        setLoginStates((prevState) => ({ ...prevState, error: !prevState.error }));
      }
    } catch (error) {
      try {
        mixpanel.track(MixPanelEvents.otpFailed, {
                isWebFlow: true,
            });
        } catch (error) {
            //
        }
    }
  };

  const handleOtpSend = async () => {
    if (isTimer) {
      return;
    }
    setLoginStates((prevState) => ({ ...prevState, isTimer: !prevState.isTimer }));
    restart(moment().add(1, 'minute').toDate());
    const postData = { mobile: mobileNumber };
    const response = await getOtpConfirmation(postData);
    if (response.statusCode === 201) {
      notify({ message: 'OTP Sent Successfully!', severity: 'success', dismissible: true });
    } else {
      notify({ message: response.message[0], severity: 'error', dismissible: true });
    }
  };

  const maskReg = new RegExp('\\+91\\d{8}(\\d{2})', 'gm');
  const match = maskReg.exec(mobileNumber ?? '');
  let maskedNumber = mobileNumber;
  if (match?.[1]) {
    maskedNumber = `+91-XXXXXXXX${match?.[1]}`;
  }

  useBeforeUnloadAndNavigate();

  return (
    <div className={css.wrapper}>
      <Seo title={'Enter your OTP'} />
      <BoxContainer
        title={<Typography variant="h1">Enter OTP</Typography>}
        subheader={
          <React.Fragment>
            <Typography variant="subheading1">
              Please enter the 4-digit OTP that we&apos;ve sent to mobile number
            </Typography>
            <Typography variant="subheading1">{maskedNumber}</Typography>
            <div className={css.otp}>
              <OtpInput
                onChange={(value) => setLoginStates((prevState) => ({ ...prevState, value: value, error: false }))}
                value={value}
                disabled={loading}
                error={error}
              />
            </div>
            <Typography variant="p" className={css.timer}>
              <span
                aria-hidden
                className={[isTimer ? css.disabled : css.secondaryTitle].join(' ')}
                onClick={handleOtpSend}
              >
                Resend
              </span>{' '}
              {isTimer && (
                <>
                  OTP in {minutes}:{seconds.toString().padStart(2, '0')} secs.
                </>
              )}
            </Typography>
          </React.Fragment>
        }
      >
        <section className={css.container}>
          <Button
            loading={loading}
            className={css.button}
            onClick={handleOtpConfirmation}
            disabled={value.length !== 4}
          >
            Confirm
          </Button>
        </section>
      </BoxContainer>
    </div>
  );
};

export default Login;
