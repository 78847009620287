import { prepareForSlot } from '@mui/base/utils';
import { Box, IconButton, Modal, TextField, useMediaQuery, useTheme } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon, TimeIcon } from '@mui/x-date-pickers';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CloseIcon from '../../assets/icons/CloseIcon';
import { AdaptiveTypography, Button, Radio, Typography } from '../../components/base';
import { CALL_MAP, MixPanelEvents, cancellationReasonsOffline } from '../../helpers/constant';
import { AppContext } from '../../helpers/hooks/AppContext';
import { CLIENT_ROUTES } from '../../router/routes';
import {
  cancelCall,
  cancelCallOffline,
  getAvailableSlotForTherapist,
  getAvailableSlots,
  getPreviousTimeSlot,
  getPreviousTimeSlotOflline,
  scheduleOfflineTimeSlot,
} from '../../services/callSchedule.service';
import { getCallInfo, getOrderStatus, orderIntakeOfflineCall } from '../../services/selfcheckin.service';
import style from './scheduleCall.module.scss';

import 'swiper/css';
import PriceText from '../../components/common/price-text';
import notify from '../../helpers/toastify-helper';
import { getOtpConfirmation, getOtpVerification, signupOfflineUser } from '../../services/register.service';


const ScheduleCallOfflinePage = ({ isPsych }: { isPsych: boolean }) => {
  const { mixpanel, appState, appDispatch } = useContext(AppContext);

  const theme = useTheme();
  const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));

  const [selectedDate, setSelectedDate] = useState<any>(null);

  const [slots, setSlots] = useState<Array<any>>([]);
  const [previousSlots, setPreviousSlots] = useState<any>(null);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [isCallCompleted, setIsCallCompleted] = useState(
    appState.stepsData?.stepsCompleted && appState.stepsData?.stepsCompleted >= 4,
  );

  const [isCallPaid, setIsCallPaid] = useState(false);

  const orderCall = async (appointment_id: string) => {
    const paymentRes = await orderIntakeOfflineCall(appointment_id,isPsych?'psychiatryIntial': 'offlineIntialCall');
    const paymentLink = paymentRes.data.payment_links.web;
    // console.log(paymentLink)

    try {
      mixpanel.track(MixPanelEvents.paymentStarted, {
        isWebFlow: true,
      });
    } catch (error) {
      //
    }
    window.location.replace(paymentLink);
  }

  const [searchParams, setSearchParams] = useSearchParams();
  let orderId = searchParams.get('order_id')
  let { id } = useParams()

  if (isPsych) {
    if (
      `${import.meta.env.REACT_APP_BASE_URL}`.includes('prod-backend') && !import.meta.env.DEV) {
      id = '50681c3c-751c-4089-af6c-11632e945cbc' //UAT PSYCH ID
    }
    else {
      id = '0b9eb812-efef-4ecf-b781-e40f5e47f07e' //PROD PSYCH ID
    }
  }

  const hasRun = useRef(false);
  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;
      initFunction()
    } else {
      console.log("init Effect runs only once");
    }
  }, []);

  const initFunction = () => {

    console.log('init')
    try {
      mixpanel.track(MixPanelEvents.callSetupPageView, {
        isWebFlow: true,
      });
    } catch (error) {
      //
    }
    const getSlots = async () => {
      try {
        setIsLoading(true);
        const slots = await getAvailableSlotForTherapist(id!);
        console.log({ slots })
        setIsLoading(false);
        setSlots(slots);
      } catch (error) {
        //ignore
      }
    };

    const getAlreadySelectedSlot = async () => {
      try {
        const previousSlotOff = await getPreviousTimeSlotOflline();
        if (previousSlotOff.slots) {
          let slots = previousSlotOff.slots
          if (slots.availableSlotsId && slots.availableSlotsId2) {
            if (slots.availableSlotsId2.startDateTime > slots.availableSlotsId.startDateTime) {
              setPreviousSlots([slots.availableSlotsId, slots.availableSlotsId2])
            } else {
              setPreviousSlots([slots.availableSlotsId2, slots.availableSlotsId])
            }
          }
        }

        // setPreviousSlots(slot);
      } catch (error) {
        //ignore
      }
    };


    const getPaidCallInfo = async () => {
      let data = await getCallInfo();

      // console.log(data)
      if (isPsych) {
        setIsCallPaid(!!data.data.totalPsychiatricCalls)
      }
      else {
        setIsCallPaid(!!data.data.introCall)
      }

      if (searchParams.get('order_id') && localStorage.getItem('offline_selected_slot')) {
        try {
          setSelectedDate(JSON.parse(localStorage.getItem('offline_selected_date') ?? ''))
          setSelectedTimeSlot(JSON.parse(localStorage.getItem('offline_selected_slot') ?? ''))
          localStorage.removeItem('offline_selected_date')
          localStorage.removeItem('offline_selected_slot')
          setAutoCall(true)
          return
        } catch (error) {
          //ignore
        }
      } else {
        await getSlots();
      }
      return;



      // console.log('getpaidcallinfo called', !!data.data.introCall)
      if (!orderId) {
        return
      }
      let orderData = await getOrderStatus(orderId!);
      console.log(orderData.data.status)

      switch ((orderData.data.status.toString().toLowerCase())) {

        case 'backpressed':
        case 'pending_vbv':
        case 'authorizing':
        case 'new':
          navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.payment_processing}`);
          break;
        case 'user_aborted':
        case 'authorization_failed':
        case 'authentication_failed':
        case 'api_failure':
          navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.payment_failed}`);
          break;
        case 'charged':
          break;

      }
      if (previousSlots) {
        console.log('previous slot so ending')
        return;
      }

      console.log({ isPaid: !!data.data.introCall, orderId })
      if (!!data.data.introCall && orderId) {
        let scheduled_time = JSON.parse(localStorage.getItem('selected_time') ?? '')
        let scheduled_date = JSON.parse(localStorage.getItem('selected_date') ?? '')
        await getSlots();
        if (scheduled_date && scheduled_time) {
          setSelectedDate(scheduled_date)
          setSelectedTimeSlot(scheduled_time)
          console.log({ scheduled_time, selectedTimeSlot })

          onNext(scheduled_time)
        }
      }


    }
    let futures = []
    let previous = getAlreadySelectedSlot();
    futures.push(previous)
    if (!isCallCompleted) {
      let slot = getSlots();
      futures.push(slot)
    }

    Promise.all(futures).finally(
      () => {
        getPaidCallInfo()
      }
    )
  }

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>(null);

  let datesSet: any = {}

  for (let day of slots) {
    datesSet[(day as any).date as any] = {}
    for (let timeslot of day.time) {
      (datesSet as any)[(day as any).date as any][(timeslot as any).startTime] = {
        'slot': timeslot,
        'bookedSlots': 0,
        'isOnlineOnly': false,
        'therapist': id,
      }
    }

  }


  const dates = Object.keys(datesSet).map((slotDate) => ({
    label: moment(slotDate, 'MM/DD/yyyy').format('DD/MM/YYYY'),
    value: slotDate,
  }));


  dates?.sort((a, b) => moment(a.label, 'DD/MM/YYYY').diff(moment(b.label, 'DD/MM/YYYY')))

  if (!selectedDate && dates.length > 0) {
    setSelectedDate(dates[0])
  }

  function combineSlots(slots: Array<any>, x: number) {
    const result = [];
    let consecutiveSlots = [];

    // Iterate over the slots array
    for (let i = 0; i < slots.length; i++) {
      if (consecutiveSlots.length === 0) {
        consecutiveSlots.push(slots[i]);
      } else {
        // Check if the current slot is consecutive with the last slot in consecutiveSlots

        if (!consecutiveSlots[consecutiveSlots.length - 1].endtime.diff(slots[i].startTime)) {
          consecutiveSlots.push(slots[i]);
        } else {
          // Process the current consecutive group if it has at least x slots
          if (consecutiveSlots.length >= x) {
            for (let j = 0; j <= consecutiveSlots.length - x; j++) {
              // Gather the ids from the consecutive group of x slots
              const combinedIds = consecutiveSlots
                .slice(j, j + x)
                .map(slot => slot.id);
              result.push({
                startTime: consecutiveSlots[j].startTime,
                endtime: consecutiveSlots[j + x - 1].endtime,
                ids: combinedIds,
                time: consecutiveSlots[j].startTime,
              });
            }
          }
          // Reset consecutiveSlots with the current non-consecutive slot
          consecutiveSlots = [slots[i]];
        }
      }
    }

    // Process any remaining consecutive group after loop ends
    if (consecutiveSlots.length >= x) {
      for (let j = 0; j <= consecutiveSlots.length - x; j++) {
        const combinedIds = consecutiveSlots
          .slice(j, j + x)
          .map(slot => slot.id);
        result.push({
          time: consecutiveSlots[j].startTime,
          startTime: consecutiveSlots[j].startTime,
          endtime: consecutiveSlots[j + x - 1].endtime,
          ids: combinedIds,
          id: combinedIds.join(',')
        });
      }
    }
    return result;
  }

  let times = selectedDate != null
    ? Object.values((datesSet as any)[selectedDate.value])
      .map(
        (slot: any) => (
          {
            label: `${moment(slot.slot.startTime).local().format('h:mm a')} to ${moment(slot.slot.endTime)
              .local()
              .format('h:mm a')}`,
            value: slot.slot.id,
            time: moment(slot.slot.startTime).local(),
            endtime: moment(slot.slot.endTime).local(),
            startTime: moment(slot.slot.startTime).local(),
            id: slot.slot.id,
          }
        )
      )
    : null;

  times?.sort((a, b) => a.time.diff(b.time))
  times = combineSlots(times ?? [], 2) as any
  //   console.log({times,times2})
  // console.log(selectedTimeSlot);
  // console.log(selectedDate);


  // console.log({datesSet})

  console.log({ times, datesSet, selectedTimeSlot })

  const [isTimeSlotExpired, setIsTimeSlotExpired] = useState(false);


  const sendOTP = async () => {
    if (userPhone.length != 10) {
      notify({ message: 'Invalid Phone Number', severity: 'error' })
      return;
    } else {
      const response = await getOtpConfirmation({ mobile: `+91${userPhone}` });
      if (response.statusCode === 201) {
        notify({ message: 'OTP Sent Successfully!', severity: 'success', dismissible: true });
        setSendOTP(true)
      } else {
        notify({ message: response.message[0], severity: 'error', dismissible: true });
      }
    }
  }

  const onNext = async (timeslot = null) => {
    console.log('next called')
    try {
      setIsLoading(true)
      // if (!isScheduleConfirmationOpen && !timeslot) {
      //   setScheduleConfirmationOpen(true)
      //   return;
      // }
      if (!localStorage.getItem('accessToken') && !signupDialogOpen) {
        setSignupDialogOpen(true)
        return;
      } else {

        try {
          if (!localStorage.getItem('accessToken')) {
            const postData = { code: otp, mobile: `+91${userPhone}` };
            const response = await getOtpVerification(postData);
            if (response?.statusCode !== 201) {
              notify({ message: response.message, severity: 'error', dismissible: true });
              return
            } else {
              console.log({ response })
              if (response?.data?.access_token) {
                navigate(`/getapp`, { relative: 'path' })
              }
            }

            const responsesignup = await signupOfflineUser(userName, userPhone)
            if (responsesignup?.statusCode === 201) {
              localStorage.setItem('accessToken', responsesignup.data?.access_token);
              localStorage.setItem('userId', responsesignup.data?.id);
            } else {
              throw "Failed"
            }
          }
        } catch (error) {
          notify({ message: 'Failed to Signup', severity: 'error' })
        }
      }
      if (!(selectedTimeSlot != null) && !timeslot) {
        // console.log('valid time not selected')
        // console.log({ selectedTimeSlot })
        return;
      }
      let newTimeSlots = await getAvailableSlots();
      var isAllowed = false;

      if (moment().isBefore(selectedTimeSlot.endtime)) {
        isAllowed = true;
      }
      if (!isAllowed) {
        setSlots(newTimeSlots);
        setSelectedDate(null)
        setSelectedTimeSlot(null)
        setScheduleConfirmationOpen(false)
        setIsTimeSlotExpired(true)
        return;
      }
      if (!isCallPaid && !timeslot && !previousSlots) {
        console.log('call info')
        let data = await getCallInfo();

        if (isPsych) {
          setIsCallPaid(!!data.data.totalPsychiatricCalls)
        }
        else {
          setIsCallPaid(!!data.data.introCall)
        }
        let isCallPaidNew: boolean = isCallPaid;

        try {
          isCallPaidNew = !!data.data.introCall
        } catch (e) {
          //ignore
        }

        // console.log({isCallPaidNew})
        if (!isCallPaidNew && !timeslot && !previousSlots) {
          if (!timeslot) {
            localStorage.setItem('selected_time', JSON.stringify(selectedTimeSlot))
            localStorage.setItem('selected_date', JSON.stringify(selectedDate))
            localStorage.setItem('offline_selected_slot', JSON.stringify(selectedTimeSlot))
            localStorage.setItem('offline_selected_date', JSON.stringify(selectedDate))
          }

          try {
            (window as any).fbq('trackCustom', 'Payment_Started')
          } catch (error) {
            console.log('Cannot send custom pixel')
          }
          orderCall((timeslot ?? selectedTimeSlot).value);

          console.log('call not paid yet')
          return;
        }
      }
      setIsLoading(true);
      try {
        const response = await scheduleOfflineTimeSlot(selectedTimeSlot.ids[0], selectedTimeSlot.ids[1]);
        mixpanel.track(previousSlots ? MixPanelEvents.reScheduleCall : MixPanelEvents.scheduleCall, {
          [previousSlots ? CALL_MAP.RescheduleCall : CALL_MAP.ScheduleCall]: 'Success',
          isWebFlow: true,
        });
        if (previousSlots) {
          notify({ message: 'Call Rescheduled', severity: 'success' })
          try {
            (window as any).fbq('trackCustom', 'Call_scheduled_event')
          } catch (error) {
            console.log('Cannot send custom pixel')
          }
          initFunction()
        } else {
          try {
            (window as any).fbq('trackCustom', 'Call_scheduled_event')
          } catch (error) {
            console.log('Cannot send custom pixel')
          }
          // navigate(`../${CLIENT_ROUTES.call_confirmation}`);
          initFunction()
        }
      } catch (error: any) {
        notify({ message: error.message, severity: 'error' })
      }
    }

    finally {
      setIsLoading(false);
    }

  };
  const onEnrolClick = () => {
    navigate(`../../${CLIENT_ROUTES.dashboard}`);
    navigate(`../../${CLIENT_ROUTES.enroll}`);
  };


  const [isCancelOpen, setCancelOpen] = useState(false);

  const [isScheduleConfirmationOpen, setScheduleConfirmationOpen] = useState(false)
  const [cancellationUnavailable, setCancellationUnavailable] = useState(false)

  const [signupDialogOpen, setSignupDialogOpen] = useState(false)
  const [userName, setUserName] = useState("")
  const [userPhone, setUserPhone] = useState("")

  const therapists = [
    {
      'name': 'Dr. Mrigaya Sinha',
      'qualification': 'Ph.D. NIMHANS, Post - Doc Staunton Clinic, USA',
      'pic': '/assets/images/therapists/Mrigaya.jpg'
    }, {
      'name': 'Ms. Raksha Rajesh',
      'qualification': 'M.Phil. in Clinical Psychology',
      'pic': '/assets/images/therapists/Raksha.jpg'
    }, {
      'name': 'Ms. Vidula Sawant',
      'qualification': 'M.Phil. in Clinical Psychology',
      'pic': '/assets/images/therapists/Vidula.jpg'
    }, {
      'name': 'Ms. Bijal Shah',
      'qualification': 'M.Phil. in Clinical Psychology',
      'pic': '/assets/images/therapists/Bijal.jpg'
    },
  ]


  let canReschedule = true
  if (previousSlots?.[0]?.startDateTime) {
    // console.log({previousSlot})
    let hoursLeft = moment(previousSlots?.[0]?.startDateTime).diff(moment(), 'hour')
    if (hoursLeft < 24) {
      canReschedule = false
    }
  }

  const [autoCall, setAutoCall] = useState(false)


  useEffect(() => {
    if (autoCall) {
      onNext()
      setAutoCall(false)
    }
  }, [autoCall])

  const [sentOTP, setSendOTP] = useState(false)
  const [otp, setOTP] = useState('')

  console.log({ previousSlots })

  return (
    <div className={style.scheduleCallContainer}>
      <Modal
        open={isTimeSlotExpired}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`} style={{ maxWidth: '800px' }}>

          <Typography variant="h3" className="mitsu-select-modal-title">
            The slot is unavailable
          </Typography>
          <div style={{ height: '32px' }} />
          <AdaptiveTypography variant="body" mobileVariant="subheading2">
            Oops! That time slot was just booked. Please choose another one.
          </AdaptiveTypography>
          <div style={{ height: '32px' }} />
          <div className={style.buttonRow}>
            < Button variant="contained-nopad" onClick={() => setIsTimeSlotExpired(false)}>
              <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                Okay
              </div>
            </Button>
          </div>
        </div>
      </Modal >
      <Modal
        open={cancellationUnavailable}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`} style={{ maxWidth: '800px' }}>

          <Typography variant="h3" className="mitsu-select-modal-title">
            Cancellation unavailable
          </Typography>
          <div style={{ height: '32px' }} />
          <AdaptiveTypography variant="body" mobileVariant="subheading2">
            We're sorry, but you can only cancel a session up to 24 hours before it starts
          </AdaptiveTypography>
          <div style={{ height: '32px' }} />
          <div className={style.buttonRow}>
            < Button variant="contained-nopad" onClick={() => setCancellationUnavailable(false)}>
              <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                Okay
              </div>
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={isScheduleConfirmationOpen}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`} style={{ maxWidth: '800px' }}>
          {previousSlots && !canReschedule ?
            <Typography variant="h3" className="mitsu-select-modal-title">
              Rescheduling unavailable
            </Typography> :
            <Typography variant="h3" className="mitsu-select-modal-title">
              Are you sure you are available at {selectedTimeSlot?.label?.split('to')?.[0]} on {moment(selectedDate?.value, 'M,D,YYYY')?.format('dddd, Do MMMM')}?
            </Typography>}
          <div style={{ height: '32px' }} />
          <AdaptiveTypography variant="body" mobileVariant="subheading2">
            {
              previousSlots ? canReschedule ? 'Note: You can only reschedule or cancel up to 24 hours before your call.' : "We're sorry, but you can only reschedule a session up to 24 hours before it starts." : 'Note: You can only reschedule or cancel up to 24 hours before your call.'
            }
          </AdaptiveTypography>
          <div style={{ height: '32px' }} />
          <div className={style.buttonRow}>
            {(!previousSlots || canReschedule) && <Button
              onClick={() => onNext()}
              loading={isLoading}
              variant={'outlined-nopad'}
              className={style.dialogSecondaryButton}
              style={{ height: 'unset', width: 'unset' }}
            >
              <div className={style.dialogButton}>Yes, schedule</div>
            </Button>}
            {(!previousSlots || canReschedule) && <span style={{ width: '24px' }} />}
            {< Button variant="contained-nopad" onClick={() => setScheduleConfirmationOpen(false)}>
              <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                {(!previousSlots || canReschedule) ? 'No' : 'Okay'}
              </div>
            </Button>}
          </div>
        </div>
      </Modal >

      <Modal
        open={signupDialogOpen}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
        onClose={()=>{setSignupDialogOpen(false)}}
      >
        <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`} style={{ maxWidth: '800px' }}>
          <Typography variant="h3" className="mitsu-select-modal-title">
            Sign-up & schedule
          </Typography>
          <div style={{ height: '8px' }} />
          <AdaptiveTypography variant="body2" className="mitsu-select-modal-title">
            Lets create an account for you to get started!
          </AdaptiveTypography>
          <div style={{ height: '32px' }} />
          <TextField label="Name" onChange={(ev) => setUserName(ev.target.value)} disabled={sentOTP} />
          <div style={{ height: '8px' }} />
          <TextField label="Phone" type='tel' onChange={(ev) => setUserPhone(ev.target.value)} disabled={sentOTP} />
          <div style={{ height: '8px' }} />

          <div className={`${style.expandableSection} ${sentOTP ? style.open : ""}`}>
            <TextField fullWidth label="OTP" type='number' onChange={(ev) => setOTP(ev.target.value)} />
          </div>
          <div style={{ height: '32px' }} />

          <div className={style.buttonRow}>
            {
              sentOTP ? <Button variant="contained-nopad" onClick={() => onNext()} loading={isLoading}>
                <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                  Confirm
                </div>
              </Button> : <Button variant="contained-nopad" onClick={() => sendOTP()} loading={isLoading}>
                <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                  Send OTP
                </div>
              </Button>
            }
          </div>
        </div>
      </Modal >

      <CancelReasonDialog slotid1={previousSlots?.[0]?.id} slotid2={previousSlots?.[1]?.id} isOpen={isCancelOpen} onClose={() => setCancelOpen(false)}
        onCancelCB={() => {
          initFunction();
          notify({ message: 'Call cancelled', severity: 'success' })
          setCancelOpen(false)
          setPreviousSlots(null)
        }}
      />
      <div className={style.scheduleCallPageCenter}>
        <div className={style.ScheduleCallPage}>
          {
            !previousSlots && <>
              <div className={`${style.scheduleCallHeadContainer} ${isTwoColumn ? '' : style.alignJustify}`}>
                <AdaptiveTypography variant="h1" mobileVariant="h3">
                  Schedule your consultation
                </AdaptiveTypography>
                <div style={{ height: '8px' }} />
                <AdaptiveTypography variant="subheading1" mobileVariant="subheading2">
                  Please choose a time slot that works best for you to start your journey toward better mental health.
                  <br />
                </AdaptiveTypography>
              </div>
              <div style={{ height: '32px' }} />
            </>
          }

          {previousSlots && (
            <div className={`${style.previousCallBody} ${isTwoColumn ? '' : style.alignJustify}`}>
              <Typography variant="h2">Your appointment details</Typography>
              <div className={style.callTitleSpacing} />
              <Typography variant="label2">{isCallCompleted ? "Your intake call was completed on:" : ""}</Typography>
              <div className={style.callTitleHeaderSpacing} />
              <div className={`${style.previousSlowRow} ${style.col_2}`}>
                <div className={style.previousItemContainer}>
                  <CalendarIcon fontSize="small" /> <Typography variant="label"> Selected date </Typography>
                  <div />{' '}
                  <Typography variant="subheading3">
                    {moment(previousSlots?.[0]?.startDateTime).local().format('DD/MM/YYYY')}

                  </Typography>
                </div>
                <div className={style.previousItemContainer}>
                  <TimeIcon fontSize="small" />
                  <Typography variant="label"> Selected time slot </Typography>
                  <div />{' '}
                  <Typography variant="subheading3">
                    {`${moment(previousSlots?.[0]?.startDateTime).local().format('h:mm a')} to ${moment(previousSlots?.[1]?.endDateTime).local().format('h:mm a')}`}

                  </Typography>
                </div>
              </div>
              <div className={style.sectionGroupGap} />
            </div>
          )}

          {!isCallCompleted && (
            <div className={`${style.scheduleCallBody} ${isTwoColumn ? '' : style.alignJustify}`}>
              <Typography variant="h2">{previousSlots ? 'Reschedule or Cancel Your appointment' : 'Select a date & time'}</Typography>
              <div className={style.callTitleSpacing} />
              <Typography variant="label2">{previousSlots ?
                <span>
                  Need to make a change? We understand. You can select a new date and time to reschedule your consultation.

                  <br /><br /> </span>
               :
                ''}
                <span>
                                  Please Note:<br /><br />
                  1. Appointments can be rescheduled up to 24 hours before the scheduled time to avoid charges.<br /><br />
                  2. For more details, refer to our Refunds and Cancellation Policy in the Terms & Conditions section.</span>
                
                </Typography>
              <div className={style.callTitleHeaderSpacing} />
              <div className={`${style.slotSelectorRow} ${style.col_2}`}>
                <DateSelector
                  dates={dates}
                  selectedDate={selectedDate}
                  onSelectDate={(date) => { setSelectedDate(date) }}
                  times={times}
                  selectedTime={selectedTimeSlot}
                  onSelectTime={(ev) => {
                    setSelectedTimeSlot(ev)
                  }}
                />
              </div>
            </div>
          )}

          <div className={style.spacer} />
          <div style={{ height: '40px' }} />
        </div>
      </div>
      {
        isCallCompleted ? (
          <div className={style.buttonRow}>
            <Button variant="contained" onClick={onEnrolClick}>
              Enrol in Program
            </Button>
          </div>
        ) : previousSlots ? (
          <div className={style.buttonRow}>
            <Button
              onClick={() => {
                if (canReschedule) {
                  setCancelOpen(true);
                } else {
                  setCancellationUnavailable(true)
                }
              }}
              variant={isTwoColumn ? 'outlined-nopad' : 'text-noshadow'}
              className={`${isTwoColumn ? '' : style.mobileButton}`}
            >
              <div className={style.buttonContent}>Cancel call</div>
            </Button>


            <span style={{ width: '24px' }} />
            <Button
              variant='contained-nopad'
              loading={isLoading}
              disabled={!(selectedTimeSlot != null)}
              onClick={() => onNext()}
            >
              <div className={style.buttonContent} style={{ height: 'unset', width: 'unset' }}>
                Reschedule call
              </div>
            </Button>
          </div>
        ) : (
          <>
            {/* {
              !isCorporate && isCallPaid && <div className={style.paidInfo}>
                Your intro call payment has been received successfully
              </div>
            } */}
            <div className={style.buttonRow}>
              <Button
                loading={isLoading}
                value="contained"
                disabled={!(selectedTimeSlot != null)}
                onClick={() => onNext()}
              >
                {!isCallPaid ? <>Schedule call for ₹<PriceText priceKey={isPsych?'psychiatryIntial':"offlineIntialCall"} /></> : 'Schedule call'}
              </Button>
            </div>
          </>
        )
      }
    </div >
  );
};
const BoxSlot = prepareForSlot(Box);

const CancelReasonDialog = ({ slotid1, slotid2, isOpen, onClose, onCancelCB }: { slotid1: string, slotid2: string, isOpen: boolean; onClose: () => void, onCancelCB: () => void }) => {
  const [confirm, setConfirm] = useState(false);

  const [optionSelected, setOptionSelected] = useState<any>(null);

  const options = cancellationReasonsOffline;

  const [otherReason, setOtherReason] = useState('');

  const handleClose = () => {
    setConfirm(false);
    setOptionSelected(null);
    onClose();
  };
  const { mixpanel } = useContext(AppContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const isCancelCallValid = optionSelected && (optionSelected.value != options.at(-1)?.value || otherReason.trim());
  const onCancel = async (reason: string) => {
    try {
      setLoading(true);
      try {
        const response = await cancelCallOffline(slotid1, slotid2, reason);

        mixpanel.track(MixPanelEvents.cancelCall, {
          [MixPanelEvents.cancelCall]: 'Success',
          isWebFlow: true,
        });
        onCancelCB()

      } catch (error) {
        notify({ message: 'Cancellation Failed', severity: 'error' })
      }
    } finally {
      setLoading(false);
    }
  };
  const onCancelCall = () => {
    onCancel(optionSelected.value == options.at(-1)?.value ? otherReason : optionSelected.label);
  };

  if (confirm) {
    return (
      <Modal
        open={isOpen}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={'mitsu-select-modal-container'}>
          <div className="mitsu-select-modal-heading">
            <Typography variant="h3" className="mitsu-select-modal-title">
              Select a reason to cancel
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <hr />
          <div className="mitsu-select-modal-body">
            {options?.map((option) => {
              if ('label' in option && 'value' in option) {
                return (
                  <div
                    key={option.value}
                    className="mitsu-select-modal-option-row"
                    onClick={() => setOptionSelected(option)}
                    aria-hidden
                  >
                    <Typography variant="label">{option.label}</Typography>
                    <div className="mitsu-select-modal-option-spacer" />
                    {<Radio checked={optionSelected?.value === option?.value} value={option.value} />}
                  </div>
                );
              } else {
                <></>;
              }
            })}
            {optionSelected?.value == options.at(-1)?.value && (
              <TextField
                value={otherReason}
                onChange={(val) => setOtherReason(val.target.value)}
                variant="standard"
                placeholder="Specify reason"
                style={{ marginTop: '5px' }}
              />
            )}
          </div>
          <Button
            loading={loading}
            disabled={!isCancelCallValid}
            onClick={onCancelCall}
            className="mitsu-select-modal-option-cta"
          >
            Cancel call
          </Button>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        open={isOpen}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`}>
          <Typography variant="h3" className="mitsu-select-modal-title">
            Are you sure you want to cancel your call with the therapist?
          </Typography>
          <div style={{ height: '32px' }} />
          <div className={style.buttonRow}>
            <Button
              onClick={() => {
                setConfirm(true);
              }}
              variant={'outlined-nopad'}
              className={style.dialogSecondaryButton}
              style={{ height: 'unset', width: 'unset' }}
            >
              <div className={style.dialogButton}>Yes, cancel</div>
            </Button>
            <span style={{ width: '24px' }} />
            <Button variant="contained-nopad" onClick={onClose}>
              <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                No
              </div>
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
};


const DateSelector = ({ dates, selectedDate, onSelectDate, times, selectedTime, onSelectTime }: {
  dates: Array<{ label: string, value: string }>,
  selectedDate: string | null,
  onSelectDate: (date: any) => void,
  times: Array<{ label: string, value: any, time: moment.Moment }> | null,
  selectedTime: any,
  onSelectTime: (time: any) => void,

}) => {

  console.log({ dates, selectedDate })
  const [shift, setShift] = useState(0)
  const days = Array.from({ length: 7 }, (_, i) => moment().add(shift + i, 'days'));

  return (
    <div style={{ width: '100%' }}>
      {/* <Typography variant='p' className={style.pickertitle}>Please pick a date and time you want to schedule your call to</Typography> */}

      <div className={style.dateMainSelector}>

        <IconButton sx={{ border: 1, borderRadius: '8px', padding: '4px' }}
          onClick={() => {
            setShift(shift - 7)
          }}
        > <ArrowLeftIcon /> </IconButton>

        <div className={style.dateNumSelectorContainer}>

          <div className={style.dateDoWSelectorBoundary}>
            {
              days.map(
                (day) => (
                  <Typography variant='label' key={day.format('ddd')} className={style.dayOfWeek}>
                    {day.format('ddd')}
                  </Typography>
                )
              )
            }
          </div>

          <div className={style.dateNumSelectorBoundary}
          >
            {
              days.map(
                (day) => {

                  console.log(day.format('DD/MM/yyyy'))
                  var isAvailable = dates.find((e) => e.label == day.format('DD/MM/yyyy'))
                  var isSelected = (selectedDate as any)?.label == day.format('DD/MM/yyyy');

                  return (
                    <Typography variant='label' key={day.format('ddd/MM')} className={`${style.dateNum} ${isAvailable ? isSelected ? style.selected : style.available : style.unavailable}`}

                      onClick={
                        () => {
                          if (isAvailable) {
                            onSelectDate(isAvailable)
                          }
                        }
                      }
                    >
                      {day.format('D')}
                      <br />
                      {day.format('MMM')}
                    </Typography>
                  )
                }
              )
            }
          </div>
        </div>

        <IconButton sx={{ border: 1, borderRadius: '8px', padding: '4px' }}
          onClick={() => {
            setShift(shift + 7)
          }}
        > <ArrowRightIcon /> </IconButton>

      </div>

      <div className={style.slotArea}>
        {
          times?.map(
            (e) => {
              var isSelected = !e.time.diff(selectedTime?.time ?? moment());
              return <div key={e.label} onClick={() => { onSelectTime(e) }} className={`${style.timechip} ${isSelected ? style.selectedSlot : style.unselectedSlot}`}>
                <Typography className={`${isSelected ? style.selectedSlot : style.unselectedSlot}`}>{e.time.format('h:mm A')}</Typography>
              </div>
            }
          )
        }
      </div>
    </div>
  )
}

export default ScheduleCallOfflinePage;
