import moment from 'moment';
import httpClient from './index.service';
import { getCurrentUserId, getUserDetails } from './selfcheckin.service';
import { getUserSteps } from './users.service';

export const getAvailableSlots = async () => {

  let today = moment()
  const response = await httpClient.post(`/appointments/all-timeslot`, {
    "date": today.format('yyyy-MM-DD'),
    "endDate": today.add('20','days').format('yyyy-MM-DD')
  });

  return response.data
};

export const getAvailableSlotForTherapist = async (id:string) => {
  const res = await httpClient.post(`/appointments/therapist/timeslot`, {
      "id":id
  })
  return res.data
}

export const scheduleTimeSlot = async (id: string) => {
  const response = await httpClient.post(`/appointments/schedule/timeslot`, {
    id: id,
  });
  try {
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const scheduleOfflineTimeSlot = async (id: string, id2:string) => {
  const response = await httpClient.post(`/appointments/offline/schedule/timeslot`, {
    'availableSlotId': id,
    'availableSlotId2': id2,
    'userId': localStorage.getItem('userId'),
    'isIntialCall': true,
  });
  try {
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getPreviousTimeSlot = async () => {
  const response = await httpClient.get(`/appointments/previous/timeslot`);
  try {
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPreviousTimeSlotOflline = async () => {
  const response = await httpClient.get(`/appointments/previous/timeslots`);
  try {
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPreviousTimeSlotNew = async () => {
  const response = await httpClient.get(`/appointments/previous/timeslots`)
  try {
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const cancelCall = async (reason: string) => {
  let res = await getPreviousTimeSlotNew()
  let slotId = res?.slots?.availableSlotsId?.id;

  const response = await httpClient.post(`/appointments/cancel-call`, {
    'cancellationReason': reason,
    'availableSlotId': slotId,
    'patientId': (await getCurrentUserId()),
  });
  try {
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const cancelCallOffline = async (id1:string, id2:string, reason: string) => {

  const response = await httpClient.post(`/appointments/cancel-call`, {
    'cancellationReason': reason,
    'availableSlotId': id1,
    'availableSlotId2': id2,
    'patientId': (await getCurrentUserId()),
  });
  try {
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getUserEmailId = async () => {
  const id = localStorage.getItem('email');
  if (!!!id) {
    const details = await getUserDetails();
    if (details.email) {
      localStorage.setItem('email', details.email);
      return details.email;
    }
  }
  return id;
};

export const getUserPhone = async () => {
  const id = localStorage.getItem('mobile');
  if (!!!id) {
    const details = await getUserDetails();
    if (details.mobile) {
      localStorage.setItem('mobile', details.mobile);
      return details.mobile;
    }
  }
  return id;
};

export const markCallScheduleStepCompleted = async () => {
  const id = await getCurrentUserId();
  try {
    const response = await getUserSteps(`${id}`);
    if (response.data.stepsCompleted >=3 ){
      return;
    }
  } catch (error) {
   //ignore 
  }
  const result = await httpClient.patch(`/users/step?id=${id}`, {
    stepsCompleted: 3,
  });
  try {
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getTherapistInfo = async () => {
  const result = await httpClient.get(`/appointments/therapist-info`);
  try {
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAllTherapists = async () => {
  const result = await httpClient.get(`/users/therapist/all-therapist`);
  try {
    return result.data;
  } catch (error) {
    console.error(error);
  }
}