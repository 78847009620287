import { Link } from 'react-router-dom';
import { AdaptiveTypography, Button } from '../../components/base';
import style from './scheduleCall.module.scss';
import { CLIENT_ROUTES } from '../../router/routes';
import {  useMediaQuery, useTheme } from '@mui/material';
import MitsuLogo from '../../assets/icons/Mitsu';
import Typography from '../../components/base/typography';
import { CalendarIcon, ClockIcon } from '@mui/x-date-pickers';
import PriceText from '../../components/common/price-text';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { MixPanelEvents } from '../../helpers/constant';
import MitsuComparisonTable from './mitsuComparisonTable';


const CallPricingPage = ({selectedDate, selectedTime, onNext}:{selectedDate:string, selectedTime:string, onNext:()=>void}) => {
    const theme = useTheme();
    
    const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
            try {
            mixpanel.track(MixPanelEvents.callPricingPageView, {
                isWebFlow: true,
            });
            } catch (error) {
            //
            }
        }
    )
    
    return (
        <div>
            <div className={style.scheduleCallPageCenter}>
                <div className={style.ScheduleCallPage}>
                <div className={`${style.scheduleCallHeadContainer} ${isTwoColumn ? '' : style.alignJustify}`}>
                    <AdaptiveTypography variant="h1" mobileVariant="h3">
                    Review your appointment
                    </AdaptiveTypography>
                    <div style={{ height: '8px' }} />
                    <AdaptiveTypography variant="subheading1" mobileVariant="subheading2">
                    Almost done! Just review your appointment before confirming.
                    </AdaptiveTypography>
                </div>
                <div style={{ height: '32px' }} />

                <div className={style.mitsuRateCard}>
                    <div className={style.mitsulogo}>
                        <MitsuLogo />

                        <div className={style.prices}>
                            <AdaptiveTypography variant='h4'><s>₹899</s></AdaptiveTypography>
                            <AdaptiveTypography variant='h3'>₹499</AdaptiveTypography>
                        </div>

                        <Typography variant='h4'>Selected slot details:</Typography>

                        <div className={style.timecards}>
                            <div className={style.timecard}> <CalendarIcon/> <Typography className={style.timeinfo}>{(selectedDate as any)?.label}</Typography> </div>
                            <div className={style.timecard}> <ClockIcon/> <Typography className={style.timeinfo}>{(selectedTime as any)?.label}</Typography> </div>

                        </div>
                    </div>

                </div>

                <div style={{ height: '48px' }} />

                <MitsuComparisonTable/>

                <div className={style.spacer} />
                <div style={{ height: '20px' }} />

                <div className={`${style.buttonRow} ${style.floating}`}>
                    <Button
                        // loading={isLoading}
                        value="contained"
                        disabled={!(selectedTime != null)}
                        onClick={() => onNext()}
                    >
                        Schedule call for ₹<PriceText priceKey="intialCall"/>
                    </Button>
                </div>
                <div style={{ height: '40px' }} />
                </div>
            </div>
        </div>
    )
}

export default CallPricingPage;