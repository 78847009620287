import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import WhiteBack from '../../assets/images/WhiteBack.svg';
import { Button, Typography } from '../../components/base/index';
import BoxContainer from '../../components/common/box-container/index';
import { MixPanelEvents } from '../../helpers/constant';
import { AppContext } from '../../helpers/hooks/AppContext';
import notify from '../../helpers/toastify-helper';
import { CLIENT_ROUTES } from '../../router/routes';
import { getMitsuVideo, getMitsuVideoName, postCompletedStep } from '../../services/users.service';
import PlayButton from './components/play-button/index';
import css from './index.module.scss';
import Rand from 'rand-seed';
import MitsuComparisonTable from '../callSchedule/mitsuComparisonTable';

const AboutUs: React.FC = () => {
  const [videoLink, setVideoLink] = React.useState();
  const [minWatchTime, setMinWatchTime] = React.useState(5000);
  const [amountSeen, setAmountSeen] = React.useState(0);
  const seenAmountRef = React.useRef(0);

  React.useEffect(() => {
    seenAmountRef.current = amountSeen;
  }, [amountSeen]);

  const [isNextAllowed, setNextAllowed] = useState(!!useSearchParam('isReview'))
  const [isCallingStepComplete, setIsCallingStepComplete] = useState(false)

  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();

  const getVideoLink = async () => {
    const response = await getMitsuVideoName().then(async (data) => {
      const {
        data: { external_link, completionTime },
      } = data;
      setMinWatchTime(completionTime * 1000);
      const response: any = await getMitsuVideo(external_link);
      if (response?.message) {
        const { message } = response;
        setVideoLink(message);
      }
    });

    return response;
  };

  const handleClick = async () => {
    if (isNextAllowed) {
      navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.dashboard}`);
      navigate(
        `/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.mood_check_in}/${CLIENT_ROUTES.mood_check_in_instructions}`,
      );
    }
  };

  useEffect(() => {
    getVideoLink();
  }, []);
  const { mixpanel } = useContext(AppContext);

  useEffect(
    () => () => {
      mixpanel.track(MixPanelEvents.introVideo, {
        'Time Elapsed': `${~~(seenAmountRef.current / 1000)} seconds`,
        isWebFlow: true,
      });
    },
    [],
  );

  const onVideoProgress = async (val: number) => {
    setAmountSeen(val)
    try {
      if (minWatchTime < amountSeen && !isNextAllowed && !isCallingStepComplete) {
        setIsCallingStepComplete(true)
        const completedStep = { stepsCompleted: 1 };
        const response: any = await postCompletedStep(userId, completedStep);
        if (response.statusCode === 200) {
          setNextAllowed(true)
        }
      }
    } catch (error: any) {
      error?.message && notify({ message: error?.message, severity: 'error', dismissible: true });
    }
  }


  return (
    <BoxContainer
      className={css.boxContainer}
      title={<Typography variant="h1" className={css.mobileleft}>Learn About Mitsu</Typography>}
      subheader={
        <Typography variant="subheading1" className={css.mobileleft}>
          Our program helps you develop skills to better manage your mental health, function better in your daily
          responsibilities, and achieve long-term emotional balance.
        </Typography>
      }
    >
      <div className={css.container}>
        <div className={css.videoContainer}>
          <div className={css.video}>
            {videoLink && <ReactPlayer
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              url={videoLink}
              width="100%"
              playing={true}
              height="100%"
              controls={true}
              playsinline={true}
              onProgress={(val) => onVideoProgress(val.playedSeconds * 1000)}
            />}
          </div>
        </div>
        
        <MitsuComparisonTable />

        <div className={css.nextStepButton}>
          <Button disabled={!isNextAllowed} onClick={handleClick} className={css.button}>
            Go to next step
          </Button>
        </div>
      </div>
    </BoxContainer>
  );
};

export default AboutUs;
