import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { CLIENT_ROUTES } from "../../router/routes";
import { useEffect } from "react";


const OrderStatusPage = () => {

    return <Navigate to={`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.dashboard}`} replace />
}

export default OrderStatusPage;
