import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { MixPanelEvents } from '../../helpers/constant';
import { AdaptiveTypography, Button } from '../../components/base';
import style from './scheduleCall.module.scss';
import PriceText from '../../components/common/price-text';
import { PlanCard, plans } from './planSelection.page';

import BrainIcon from '../../assets/images/brain_icon.svg'
import MessageIcon from '../../assets/images/message_icon.svg'
import CallIcon from '../../assets/images/call_image.svg'
import GrowthIcon from '../../assets/images/growth_icon.svg'
import SmileIcon from '../../assets/images/smilie_icon.svg'
import LockIcon from '../../assets/icons/LockIcon';
import { getUserDetails, orderTrial } from '../../services/selfcheckin.service';


const TrialPage = () => {
    const theme = useTheme();

    const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));

    const [loading, setIsLoading] = useState(false)

    const [isPaid, setIsPaid] = useState(false)


    const checkPayment = async () => {
        const details = await getUserDetails();

        if (details.isTrialPeriod) {
            setIsPaid(true)
        }
        if (details.isPaymentCompleted) {
            setIsPaid(true)
        }
    };

    useEffect(() => {
        checkPayment()
    }, []
    )

    const onNext = async () => {
        setIsLoading(true)
        const paymentRes = await orderTrial()

        const paymentLink = paymentRes.data.payment_links.web;
        // console.log(paymentLink)

        try {
            mixpanel.track(MixPanelEvents.paymentEntered, {
                isWebFlow: true,
                'Payment Entered': 'Success',
                'Payment Type': 'Trial',
            });
        } catch (error) {
            //
        }
        window.location.replace(paymentLink);
    }

    const items = [
        {
            'title': 'First week of personalized lessons and activities',
            'subtitle': 'Revisit anytime with lifetime access.',
            'icon': BrainIcon
        },
        {
            'title': '1 Week of Chat Support with Your Therapist',
            'subtitle': 'No waiting—get daily support when you need it.',
            'icon': MessageIcon
        },
        {
            'title': '1 therapist call of 30-min duration',
            'subtitle': 'Schedule the call whenever you want.',
            'icon': CallIcon
        },
        {
            'title': 'Monthly self check-in',
            'subtitle': 'Track how your symptoms change during the program.',
            'icon': GrowthIcon,
            'isAllowed': false,
        },
        {
            'title': 'Mood log',
            'subtitle': 'Build self-awareness to better manage emotions.',
            'icon': SmileIcon
        },

    ]

    return (
        <div>
            <div style={{ height: '20px' }} />
            <PlanCard plan={plans.at(1)!} hideArrow />
            <div style={{ height: '20px' }} />
            <div className={style.planItems}>
                {
                    items.map((item) => <div className={style.planItemTop}>
                        {
                            item.isAllowed == false && <div className={style.lockDiv}>
                                <LockIcon className={style.lockIcon} />
                                <AdaptiveTypography variant='label' >
                                    Available only with 8-week program
                                </AdaptiveTypography>
                            </div>
                        }
                        <div className={style.planItem}>
                            <img src={item.icon} className={style.planItemImage} />
                            <div className={style.planTextDiv}>
                                <AdaptiveTypography variant='subheading1' >{item.title}</AdaptiveTypography>
                                <AdaptiveTypography variant='body' >{item.title}</AdaptiveTypography>
                            </div>
                        </div>
                    </div>)
                }
            </div>
            <div style={{ height: '20px' }} />

            {
                !isPaid && <>
                    <div className={`${style.buttonRow} ${style.floating}`}>
                        <Button
                            loading={loading}
                            value="contained"
                            onClick={() => onNext()}
                        >
                            Start your Trial
                        </Button>
                    </div>
                    <div style={{ height: '40px' }} />
                </>
            }
        </div>
    )
}


export default TrialPage;