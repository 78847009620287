import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MixPanelEvents } from "../../helpers/constant";
import { trackConversion } from "../../services/register.service";

const ProgramsLandingPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        try {
            trackConversion('Program Landing')

        } catch (error) {
            
        }
        try {
            (window as any).fbq('trackCustom', 'Program Landing')
        } catch (error) {
            console.log('Cannot send custom pixel')
        }
        try {
            mixpanel.track(MixPanelEvents.programLanding, {
                isWebFlow: true,
            });
        } catch (error) {
            //
        }
    }
    )
    useEffect(() => {

        const handleMessage = (event: any) => {
            console.log('msg received')
            console.log({ event })
            if (event.data?.event === "signup_clicked") {
                try {
                    trackConversion('SignupClicked')
                } catch (error) {
                    
                }
                try {
                    mixpanel.track(MixPanelEvents.signupClicked, {
                        isWebFlow: true,
                    });
                } catch (error) {
                    //
                }
                navigate("/");
            }else if (event.data?.event === "chat_clicked") {
                try {
                    trackConversion('ChatClicked')
                } catch (error) {
                    
                }
                try {
                    mixpanel.track(MixPanelEvents.chatClicked, {
                        isWebFlow: true,
                    });
                } catch (error) {
                    //
                }
                window.location.href = "https://wa.me/9987806854?text=Hello,%20I%20am%20interested%20in%20Mitsu%27s%20program.%20How%20can%20I%20learn%20more?";
            }
        };

        window.addEventListener("message", handleMessage);
        return () => window.removeEventListener("message", handleMessage);
    }, [navigate]);

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 100 }}>
            <iframe
                style={{ width: '100%', height: '100%', border: 'none' }}
                src="https://mitsu.care/programs?isEmbed=true"
                className="w-full h-full border-none"
                allowFullScreen
            />
        </div>
    );
};

export default ProgramsLandingPage;
