import { useLocation, useNavigate } from 'react-router-dom';
import MitsuLogo from '../../../assets/icons/Mitsu';
import WhatsAppImg from '../../../assets/icons/Whatsapp';
import { CLIENT_ROUTES } from '../../../router/routes';
import Typography from '../../base/typography/index';
import css from './index.module.scss';
import { useSearchParam } from 'react-use';
import { AdaptiveTypography, Button } from '../../base';
import PhoneIcon from '../../../assets/icons/PhoneIcon';
import { Box, MenuItem, Modal, TextField } from '@mui/material';
import { useState } from 'react';
import { submitFormWP } from '../../../pages/callSchedule/serviceSelection.page';
import notify from '../../../helpers/toastify-helper';
import { prepareForSlot } from '@mui/base';

interface IHeaderProps {
  isLogin?: boolean;
}

const Header = (props: IHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = location.pathname.includes(CLIENT_ROUTES.onboarding);

  const hideHeader = useSearchParam('hideHeader')

  const navigateDashboard = () => {
    if (isLoggedIn) {
      navigate(`${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.dashboard}`);
    }
  };

  const showCallBack = location.pathname.includes('offline/');;

  return !hideHeader && (
    <div className={css.headerContainer}>
      <div onClick={navigateDashboard} className={css.mitsuLogo} aria-hidden>
        <MitsuLogo />
      </div>
      {showCallBack && <RequestCallBackButton />}
      {isLoggedIn && (
        <div className={css.whatsAppImg}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/9987806854?text=Hello,%20I%20am%20interested%20in%20Mitsu%27s%20program.%20How%20can%20I%20learn%20more"
          >
            <WhatsAppImg />
          </a>
          <Typography>Support</Typography>
        </div>
      )}
    </div>
  );
};
const BoxSlot = prepareForSlot(Box);

const RequestCallBackButton = () => {
  const [isOpenForm, setIsOpenForm] = useState(false);

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [preferredTime, setPreferredTime] = useState('')
  const [therapyType, setTherapyType] = useState("Unknown")
  const [loading, setLoading] = useState(false)


  const submitForm = async () => {
    try {
      setLoading(true)
      let res = await submitFormWP(name, phone, preferredTime, therapyType)
      if (res) {
        notify({ message: 'Successfully Submitted', severity: 'success' })
        setIsOpenForm(false)
      } else {
        notify({ message: 'Failed to submit', severity: 'error' })
      }
    } catch (error) {
      notify({ message: (error as Error).message, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  console.log({ isOpenForm })
  return <div className={css.requestCallback} onClick={() => {
    if (!isOpenForm) {
      setIsOpenForm(true)
    }
  }}>

    <Modal
      open={isOpenForm}
      slots={{
        backdrop: BoxSlot,
      }}
      slotProps={{
        backdrop: {
          className: 'mitsu-select-modal-backdrop',
        },
      }}
      onClose={()=>{setIsOpenForm(false)}}
    >
      <div className={`mitsu-select-modal-container ${css.cancelModalContainer}`} style={{ maxWidth: '800px' }}>
        <Typography variant="h5" className="mitsu-select-modal-title">
          We are here to help!
        </Typography>
        <div style={{ height: '8px' }} />
        <AdaptiveTypography variant="body2" className="mitsu-select-modal-title">
          Fill out your details, and our team will get in touch with you shortly.
        </AdaptiveTypography>
        <div style={{ height: '32px' }} />
        <TextField label="Name" onChange={(ev) => setName(ev.target.value)} />
        <div style={{ height: '8px' }} />
        <TextField label="Phone" type='tel' onChange={(ev) => setPhone(ev.target.value)} />
        <div style={{ height: '8px' }} />
        <TextField
          select
          label="Preferred time to call"
          sx={{ "& .MuiSelect-select": { textAlign: "left" } }}
          value={preferredTime}
          onChange={(e) => setPreferredTime(e.target.value)}
          margin="dense"
        >
          {['Morning (9 AM - 12 PM)', 'Afternoon (12 PM - 4 PM)', 'Evening (4 PM - 6 PM)'].map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </TextField>
        <div style={{ height: '32px' }} />

        <div className={css.buttonRow}>

          <Button variant="contained-nopad" onClick={() => submitForm()} loading={loading}>
            <div className={css.dialogButton} style={{ height: 'unset', width: 'unset' }}>
              Submit
            </div>
          </Button>

        </div>
      </div>
    </Modal >

    <PhoneIcon />
    <AdaptiveTypography variant='label'>Request Callback</AdaptiveTypography>
  </div>
}

export default Header;