import { Typography, useMediaQuery, useTheme } from '@mui/material';
import AdaptiveTypography from '../../components/base/adaptive-typography';
import style from './scheduleCall.module.scss';
import MitsuLogo from '../../assets/icons/Mitsu';
import PriceText from '../../components/common/price-text';



const MitsuComparisonTable = (
    {
        title,
        description,
        firstTitle,
        secondTitle,
        titles,
        firstPoints,
        secondPoints
    }: {
        title?: string | null,
        description?: string | null,
        firstTitle?: JSX.Element | null,
        secondTitle?: string | null,
        titles?: Array<string> | null,
        firstPoints?: Array<JSX.Element> | null,
        secondPoints?: Array<string> | null
    }
) => {
    const theme = useTheme();

    const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <div>
            <div className={`${style.scheduleCallHeadContainer} ${isTwoColumn ? '' : style.alignJustify}`}>
                <AdaptiveTypography variant="h1" mobileVariant="h3">
                    {title ?? 'Mitsu vs. Traditional Therapy'}
                </AdaptiveTypography>
                <div style={{ height: '8px' }} />
                <AdaptiveTypography variant="subheading1" mobileVariant="subheading2">
                    {description ?? 'Still unsure? Here’s why Mitsu is a smarter, more effective, and affordable choice over traditional therapy.'}
                </AdaptiveTypography>
            </div>
            <div style={{ height: '32px' }} />

            <div className={style.comparisonContainer}>
                <div style={{ flexGrow: 1, width: '100%' }} />
                <div className={style.mitsuComparisonLogo}><AdaptiveTypography variant='body'>{firstTitle ?? <MitsuLogo />}</AdaptiveTypography></div>
                <div className={style.headerTraditional}>
                    <AdaptiveTypography variant='body'> {secondTitle ?? 'Traditional Therapy'} </AdaptiveTypography>
                </div>

            </div>
            <div className={style.comparisonContainer}>
                <div className={style.titles}>
                    {
                        (titles ?? [
                            'Cost',
                            '1-on-1 Video sessions',
                            'Therapist chat support',
                            'Results',
                            'Flexibility',
                            'Interactive videos & exercises'
                        ]).map(
                            (t) => <div key={t} className={style.titlecell}>
                                <AdaptiveTypography variant='body' >{t}</AdaptiveTypography>
                            </div>
                        ).flatMap(
                            (el, i) => i == 0 ? [el] : [
                                <div style={{ height: '1px', width: '100%', padding: '0px 10px' }} >
                                    <div style={{ backgroundColor: 'rgba(160, 160, 160, 0.3)', width: '100%', height: '100%' }} />
                                </div>
                                , el
                            ]
                        )
                    }
                </div>

                <div className={style.mitsupoints}>
                    {
                        (firstPoints ?? [
                            '₹4,999',
                            'Yes',
                            'Unlimited chat access',
                            '80% improvement',
                            'High',
                            'Yes'
                        ]).map(
                            (t, i) => <div key={i} className={style.cell}>
                                <Typography className={style.cellmitsu}>{t}</Typography>
                            </div>
                        ).flatMap(
                            (el, i) => i == 0 ? [el] : [
                                <div style={{ height: '1px', width: '100%', padding: '0px 10px' }} >
                                    <div style={{ backgroundColor: 'rgba(160, 160, 160, 0.3)', width: '100%', height: '100%' }} />
                                </div>
                                , el
                            ]
                        )
                    }
                </div>

                <div className={style.traditionalpoints}>
                    {
                        (secondPoints ?? [
                            '₹15,000 - ₹20,000',
                            'Yes',
                            'No',
                            'Varies',
                            'Low - Medium',
                            'No',
                        ]).map(
                            (t) => <div key={t} className={style.cell}>
                                <AdaptiveTypography variant='body'>{t}</AdaptiveTypography>
                            </div>
                        ).flatMap(
                            (el, i) => i == 0 ? [el] : [
                                <div style={{ height: '1px', width: '100%', padding: '0px 10px' }} >
                                    <div style={{ backgroundColor: 'rgba(160, 160, 160, 0.3)', width: '100%', height: '100%' }} />
                                </div>, el
                            ]
                        )
                    }
                </div>

            </div>
        </div>
    )
}

export default MitsuComparisonTable;