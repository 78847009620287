import {  useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { MixPanelEvents } from '../../helpers/constant';
import { AdaptiveTypography } from '../../components/base';
import style from './scheduleCall.module.scss';
import PriceText from '../../components/common/price-text';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import MitsuComparisonTable from './mitsuComparisonTable';
import { CLIENT_ROUTES } from '../../router/routes';
import { useNavigate } from 'react-router-dom';


interface Plan {
    label: string;
    title: string;
    price: string;
    description: JSX.Element;
    color: string;
    path: string;
}

const hexToRgb = (hex:string) => {
    hex = hex.replace(/^#/, '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return `${r}, ${g}, ${b}`;
};

export const plans: Array<Plan> = [
    {
        'label': 'Best value',
        'title': 'Full program',
        'price': 'program',
        'description': <>
            Get the best value and results-plus a <b>1-week money-back guarantee!</b>
        </>,
        'color': hexToRgb('#FFE600'),
        'path': `/onboarding/setup_free_call/program_page`
    },
    {
        'label': 'Trial Plan',
        'title': '1-week trial',
        'price': 'trial',
        'description': <>
            Use this option to explore Mitsu’s program for a week.
        </>,
        'color':hexToRgb('#3DEBF4'),
        'path': `/onboarding/setup_free_call/trial_page`
    }
];

const PlanSelectionPage = () => {
    const theme = useTheme();
    
    const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
            // try {
            // mixpanel.track(MixPanelEvents.callPricingPageView, {
            //     isWebFlow: true,
            // });
            // } catch (error) {
            // //
            // }
        }
    )
      const navigate = useNavigate();
    
    
    return (
        <div>
            <div style={{height:'20px'}} />
            <AdaptiveTypography variant="h2">Choose a plan</AdaptiveTypography>
            <div style={{height:'20px'}} />

            <div className={style.plansContainer}>
                {
                    plans.map((plan)=><PlanCard onClick={()=>{
                        navigate(plan.path);
                    }} key={plan.title} plan={plan} />)
                }
            </div>

            <div style={{height:'20px'}} />
            
            <MitsuComparisonTable
                title={'Compare Plans: Full Program vs. 1-week trial'}
                description={''}
                firstTitle={<>Full Program<br/>(Best Value)</>}
                secondTitle={'1-week Trial'}
                titles={[
                    'Price (Pay now)',
                    'Therapist chat access',
                    'One-on-One Calls with Therapist',
                    'Self-therapy content',
                    'Money back guarantee',
                    'Upgrade to full program'
                ]}
                firstPoints={
                    [
                        <>
                            <span style={{textDecoration:'line-through',fontSize:'12px'}}>₹5,499</span><br/>₹4,999
                        </>,
                        <>8 weeks</>,
                        <>3 calls</>,
                        <>8 weeks of guided content</>,
                        <>Yes</>,
                        <>Already included</>
                    ]
                }
                secondPoints={[
                    '₹1,299',
                    '1 week',
                    '1 call',
                    '1 week',
                    'No',
                    '₹4200'
                ]}
            />
        </div>
    )
}

export const PlanCard = ({plan,onClick, hideArrow}:{plan:Plan, onClick?:()=>void, hideArrow?:boolean}) => {
    return (
        <div className={style.planCard}
            onClick={onClick}
            style={{
                boxShadow:`0px 2px 4px rgba(${plan.color}, 0.02),
  0px 4px 8px rgba(${plan.color}, 0.03),
  0px 8px 16px rgba(${plan.color}, 0.03),
  0px 14px 29px rgba(${plan.color}, 0.04),
  0px 25px 54px rgba(${plan.color}, 0.05)`
            }}
        >
            <div className={style.planCircle} style={{backgroundColor:`rgba(${plan.color},0.7)`}} />
            <AdaptiveTypography variant='label'>{plan.label}</AdaptiveTypography>
            {/* <div style={{height:'20px'}} /> */}
            <div style={{display:'flex'}} >
                <AdaptiveTypography variant='h3'>{plan.title}</AdaptiveTypography>
                <div style={{flexGrow:1}} />

                { !hideArrow && <ArrowRightIcon fontSize='large' style={{zIndex:10}}/>}
            </div>
            <AdaptiveTypography variant='h4'> ₹<PriceText priceKey={plan.price} /> </AdaptiveTypography>
            <div style={{height:'10px'}} />
            <div style={{height:'1px', width:'100%', backgroundColor:'rgba(160, 160, 160, 0.3)'}} />
            <div style={{height:'10px'}} />
            <AdaptiveTypography variant='subheading1' > {plan.description} </AdaptiveTypography>
        </div>
    )
}



export default PlanSelectionPage;