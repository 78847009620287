import { AdaptiveTypography, Button, Modal, } from "../../components/base"
import style from './scheduleCall.module.scss';

import TherapyIcon from '../../assets/images/therapy.png'
import PsychiatryIcon from '../../assets/images/psychiatry.png'
import CouplesIcon from '../../assets/images/couples.png'
import HomeVisitIcon from '../../assets/images/homevisit.png'
import AssessmentIcon from '../../assets/images/assessment.png'

import PriceText from "../../components/common/price-text";
import { useNavigate } from "react-router-dom";
import {  prepareForSlot } from "@mui/base";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { TextField, MenuItem } from "@mui/material";
import notify from "../../helpers/toastify-helper";

const BoxSlot = prepareForSlot(Box);


const ServiceSelectionPage = () => {

    let navigate = useNavigate()

    const [isOpenForm, setIsOpenForm] = useState(false);

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [preferredTime, setPreferredTime] = useState('')
    const [therapyType, setTherapyType] = useState("")
    const [loading, setLoading] = useState(false)

    const submitForm = async () => {
        try {
            setLoading(true)
            let res = await submitFormWP(name,phone,preferredTime, therapyType)
            if(res){
                notify({message:'Successfully Submitted', severity:'success'})
                setIsOpenForm(false)
            }else{
                notify({message:'Failed to submit', severity:'error'})
            }
        } catch (error) {
            notify({message:(error as Error).message, severity:'error'})
        }finally {
            setLoading(false)
        }
    }

    return <div className={style.scheduleCallContainer}>

        <Modal
            open={isOpenForm}
            slots={{
                backdrop: BoxSlot,
            }}
            slotProps={{
                backdrop: {
                    className: 'mitsu-select-modal-backdrop',
                },
            }}
            onClose={()=>{setIsOpenForm(false)}}
        >
            <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`} style={{ maxWidth: '800px' }}>
                <Typography variant="h5" className="mitsu-select-modal-title">
                    We are here to help!
                </Typography>
                <div style={{ height: '8px' }} />
                <AdaptiveTypography variant="body2" className="mitsu-select-modal-title">
                    Fill out your details, and our team will get in touch with you shortly.
                </AdaptiveTypography>
                <div style={{ height: '32px' }} />
                <TextField label="Name" onChange={(ev) => setName(ev.target.value)} />
                <div style={{ height: '8px' }} />
                <TextField label="Phone" type='tel' onChange={(ev) => setPhone(ev.target.value)} />
                <div style={{ height: '8px' }} />
                <TextField
                        select
                        label="Preferred time to call"
                        sx={{ "& .MuiSelect-select": { textAlign: "left" } }}
                        value={preferredTime}
                        onChange={(e) => setPreferredTime(e.target.value)}
                        margin="dense"
                >
                    {['Morning (9 AM - 12 PM)','Afternoon (12 PM - 4 PM)','Evening (4 PM - 6 PM)'].map((time) => (
                    <MenuItem key={time} value={time}>
                        {time}
                    </MenuItem>
                    ))}
                </TextField>
                <div style={{ height: '32px' }} />

                <div className={style.buttonRow}>

                    <Button variant="contained-nopad" onClick={() => submitForm()} loading={loading}>
                        <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                            Submit
                        </div>
                    </Button>

                </div>
            </div>
        </Modal >
        <AdaptiveTypography variant='h1'>Choose the Right Care for You</AdaptiveTypography>
        <div style={{ height: '8px' }} />
        <AdaptiveTypography variant='body2'>Expert-led mental health services to support your well-being.
        </AdaptiveTypography>
        <div style={{ height: '20px' }} />

        <div className={style.servicesCards}>
            <div className={`${style.serviceCard} ${style.yellowBg}`}>
                <img className={style.serviceImage} src={TherapyIcon} />
                <div className={style.serviceInfoBox}>
                    <AdaptiveTypography variant='h4'> Individual Therapy                    </AdaptiveTypography>
                    {/* <AdaptiveTypography variant='body'> {therapist.education} </AdaptiveTypography> */}
                    <AdaptiveTypography variant='body2'>Personalized support for emotional well-being.
                    </AdaptiveTypography>
                    <div style={{ flexGrow: 1, minHeight: '20px' }} />

                    <div className={style.serviceButtonContainer}>
                        <Button onClick={() => { navigate(`/offline/therapists`, { relative: 'path' }) }} style={{ width: 'fit-content' }}>GET STARTED</Button>
                        {/* <Button variant='outlined' onClick={()=>{navigate(`/therapist?id=${therapist.id}`,{relative:'path'})}} style={{width:'fit-content'}}>Detail</Button> */}

                    </div>
                </div>

            </div>

            <div className={`${style.serviceCard} ${style.blueBg}`}>
                <img className={style.serviceImage} src={PsychiatryIcon} />
                <div className={style.serviceInfoBox}>
                    <AdaptiveTypography variant='h4'> Psychiatry </AdaptiveTypography>
                    {/* <AdaptiveTypography variant='body'> {therapist.education} </AdaptiveTypography> */}
                    <AdaptiveTypography variant='body2'>Accurate diagnosis and medication management.</AdaptiveTypography>
                    <div style={{ flexGrow: 1, minHeight: '20px' }} />

                    <div className={style.serviceButtonContainer}>
                        <Button onClick={() => { 
                            // navigate(`/offline/callbook/psychiatrist`, { relative: 'path' })
                            setTherapyType('Psychiatrist');
                            setIsOpenForm(true);
                        }} style={{ width: 'fit-content' }}>GET STARTED</Button>
                        {/* <Button variant='outlined' onClick={()=>{navigate(`/therapist?id=${therapist.id}`,{relative:'path'})}} style={{width:'fit-content'}}>Detail</Button> */}

                    </div>
                </div>

            </div>

            <div className={`${style.serviceCard} ${style.yellowBg}`}>
                <img className={style.serviceImage} src={CouplesIcon} />
                <div className={style.serviceInfoBox}>
                    <AdaptiveTypography variant='h4'> Couples Therapy </AdaptiveTypography>
                    {/* <AdaptiveTypography variant='body'> {therapist.education} </AdaptiveTypography> */}
                    <AdaptiveTypography variant='body2'>Expert-led therapy to help you grow together.
                    </AdaptiveTypography>
                    <div style={{ flexGrow: 1, minHeight: '20px' }} />

                    <div className={style.serviceButtonContainer}>
                        <Button onClick={() => { 
                            setTherapyType('Couples Theray');
                            setIsOpenForm(true);
                        }} style={{ width: 'fit-content' }}>GET STARTED</Button>
                        {/* <Button variant='outlined' onClick={()=>{navigate(`/therapist?id=${therapist.id}`,{relative:'path'})}} style={{width:'fit-content'}}>Detail</Button> */}

                    </div>
                </div>

            </div>

            <div className={`${style.serviceCard} ${style.blueBg}`}>
                <img className={style.serviceImage} src={HomeVisitIcon} />
                <div className={style.serviceInfoBox}>
                    <AdaptiveTypography variant='h4'> Home Therapy visits </AdaptiveTypography>
                    {/* <AdaptiveTypography variant='body'> {therapist.education} </AdaptiveTypography> */}
                    <AdaptiveTypography variant='body2'>Convenient and caring therapy for seniors at home.
                    </AdaptiveTypography>
                    <div style={{ flexGrow: 1, minHeight: '20px' }} />

                    <div className={style.serviceButtonContainer}>
                        <Button onClick={() => { 

                            setTherapyType('Home Visit');
                            setIsOpenForm(true);
                         }} style={{ width: 'fit-content' }}>GET STARTED</Button>
                        {/* <Button variant='outlined' onClick={()=>{navigate(`/therapist?id=${therapist.id}`,{relative:'path'})}} style={{width:'fit-content'}}>Detail</Button> */}

                    </div>
                </div>

            </div>

            <div className={`${style.serviceCard} ${style.yellowBg}`}>
                <img className={style.serviceImage} src={AssessmentIcon} />
                <div className={style.serviceInfoBox}>
                    <AdaptiveTypography variant='h4'> Psychological Assessments </AdaptiveTypography>
                    {/* <AdaptiveTypography variant='body'> {therapist.education} </AdaptiveTypography> */}
                    <AdaptiveTypography variant='body2'>Expert assessments for diagnosis and treatment planning.
                    </AdaptiveTypography>
                    <div style={{ flexGrow: 1, minHeight: '20px' }} />

                    <div className={style.serviceButtonContainer}>
                        <Button onClick={() => { 

                            setTherapyType('Assessment');
                            setIsOpenForm(true);
                         }} style={{ width: 'fit-content' }}>GET STARTED</Button>
                        {/* <Button variant='outlined' onClick={()=>{navigate(`/therapist?id=${therapist.id}`,{relative:'path'})}} style={{width:'fit-content'}}>Detail</Button> */}

                    </div>
                </div>

            </div>

        </div>
    </div>
}

export async function submitFormWP(
    name: string,
    phone: string,
    timeSlot: string,
    therapyType: string
): Promise<boolean> {
    try {
        const response = await fetch("https://mitsu.care/wp-json/custom/v1/notify-callback-request", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
          body: JSON.stringify({
            name,
            mobile: phone,
            timeSlot,
            therapyType,
          }),
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to submit form");
        }
    
        return true;
      } catch (error) {
        throw new Error(error instanceof Error? error.message : "Unknown error occurred");
      }
}


export default ServiceSelectionPage

