import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { MixPanelEvents } from '../../helpers/constant';
import { AdaptiveTypography, Button } from '../../components/base';
import style from './scheduleCall.module.scss';
import PriceText from '../../components/common/price-text';
import { PlanCard, plans } from './planSelection.page';

import BrainIcon from '../../assets/images/brain_icon.svg'
import MessageIcon from '../../assets/images/message_icon.svg'
import CallIcon from '../../assets/images/call_image.svg'
import GrowthIcon from '../../assets/images/growth_icon.svg'
import SmileIcon from '../../assets/images/smilie_icon.svg'
import LockIcon from '../../assets/icons/LockIcon';
import { checkCoupon, getPaymentTypes, getUserDetails, orderProgram, orderTrial } from '../../services/selfcheckin.service';
import { TextField } from '../../components/common';

import notify from '../../helpers/toastify-helper';

const ProgramPage = () => {
    const theme = useTheme();

    const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));

    // useEffect(() => {
    //         try {
    //         mixpanel.track(MixPanelEvents.callPricingPageView, {
    //             isWebFlow: true,
    //         });
    //         } catch (error) {
    //         //
    //         }
    //     }
    // )


    const [loading, setIsLoading] = useState(false)

    const [isPaid, setIsPaid] = useState(false)

    const [promocode, setPromoCode] = useState('')

    const [price, setprice] = useState<number|null>(null)
    
    useEffect(
        ()=>{
            const getAndUpdatePrice = async()=> {
                var prices = await getPaymentTypes()
                for(var price of prices.data){
                    if(price.paymentType == 'program'){
                        setprice(Number.parseInt(price.amount))
                        return
                    }
                }
            }
            getAndUpdatePrice()
        },
        []
    )

    const [savedAmount, setSavedAmount] = useState(0)
    const [coupon_id, setCouponId] = useState<string|null>(null)

    const applyPromocode = async () => {
        if(savedAmount>0){
            setSavedAmount(0)
            setPromoCode('')
            setCouponId(null)
            return;
        }
        try {
            const result = await checkCoupon(promocode)
            setSavedAmount(Number.parseInt(result.data.savedAmount))
            setCouponId(result.data.coupon_id)
        } catch (error) {
            notify({
                message:(error as any).message,
                severity:'error'
            } )
        }
    }


    const checkPayment = async () => {
        const details = await getUserDetails();

        if (details.isTrialPeriod) {
            setIsPaid(true)
        }
        if (details.isPaymentCompleted) {
            setIsPaid(true)
        }
    };

    useEffect(() => {
        checkPayment()
    }, []
    )

    const onNext = async () => {
        setIsLoading(true)
        const paymentRes = await orderProgram(coupon_id?coupon_id:undefined)

        const paymentLink = paymentRes.data.payment_links.web;
        // console.log(paymentLink)

        try {
            mixpanel.track(MixPanelEvents.paymentEntered, {
                isWebFlow: true,
                'Payment Entered': 'Success',
                'Payment Type': 'Program',
            });
        } catch (error) {
            //
        }
        window.location.replace(paymentLink);
    }


    const items = [
        {
            'title': '8 weeks of personalized lessons and activities',
            'subtitle': 'Revisit anytime with lifetime access.',
            'icon': BrainIcon
        },
        {
            'title': '8 Week of Chat Support with Your Therapist',
            'subtitle': 'No waiting—get daily support when you need it.',
            'icon': MessageIcon
        },
        {
            'title': '3 therapist call of 30-min duration',
            'subtitle': 'Schedule the call whenever you want.',
            'icon': CallIcon
        },
        {
            'title': 'Monthly self check-in',
            'subtitle': 'Track how your symptoms change during the program.',
            'icon': GrowthIcon,
            'isAllowed': true,
        },
        {
            'title': 'Mood log',
            'subtitle': 'Build self-awareness to better manage emotions.',
            'icon': SmileIcon
        },

    ]

    return (
        <div>
            <div style={{ height: '20px' }} />
            <PlanCard plan={plans.at(0)!} hideArrow />
            <div style={{ height: '20px' }} />
            <div className={style.planItems}>
                {
                    items.map((item) => <div className={style.planItemTop}>
                        {
                            item.isAllowed == false && <div className={style.lockDiv}>
                                <LockIcon className={style.lockIcon} />
                                <AdaptiveTypography variant='label' >
                                    Available only with 8-week program
                                </AdaptiveTypography>
                            </div>
                        }
                        <div className={style.planItem}>
                            <img src={item.icon} className={style.planItemImage} />
                            <div className={style.planTextDiv}>
                                <AdaptiveTypography variant='subheading1' >{item.title}</AdaptiveTypography>
                                <AdaptiveTypography variant='body' >{item.title}</AdaptiveTypography>
                            </div>
                        </div>
                    </div>)
                }
            </div>
            <div style={{ height: '20px' }} />
            {
                !isPaid &&
                <div className={style.programPaymentBorder}>


                    <AdaptiveTypography variant='h4'>Promo code</AdaptiveTypography>
                    <AdaptiveTypography>Enter a promo code to avail discount</AdaptiveTypography>
                    <div style={{ height: '20px' }} />

                    <TextField
                        inputClassName={style.inputLong}
                        name="promocode"
                        disabled={savedAmount>0}
                        value={promocode}
                        onChange={(event) => setPromoCode(event.target.value)}
                        label=""
                        placeholder="Enter promo code"
                        width={'100%'}
                        required
                        rootClassName={style.inputLong}
                        endIcon={promocode? <AdaptiveTypography onClick={()=>applyPromocode()} className={style.blue}>{savedAmount>0?'Remove':'Apply'}</AdaptiveTypography>:null}
                    />
                    {
                        savedAmount>0 && <AdaptiveTypography className={style.savedMoneyText}> You saved ₹{savedAmount} with this code </AdaptiveTypography>
                    }
                    <div style={{ height: '30px' }} />

                    <div className={`${style.buttonRow} ${style.floating} ${style.paymentgroup}`}>
                        <div style={{display:'flex'}}>
                            <AdaptiveTypography>Mitsu Program fee</AdaptiveTypography>
                            <div style={{flexGrow:1}} />
                            <AdaptiveTypography>₹<PriceText priceKey='program' /></AdaptiveTypography>
                        </div>
                        {
                            savedAmount>0 && <>
                            <div style={{display:'flex'}}>
                                <AdaptiveTypography>Coupon discount applied</AdaptiveTypography>
                                <div style={{flexGrow:1}} />
                                <AdaptiveTypography>-₹{savedAmount}</AdaptiveTypography>
                            </div>
                            <div style={{display:'flex'}}>
                                <AdaptiveTypography><span style={{fontWeight:600}}>Net amount</span></AdaptiveTypography>
                                <div style={{flexGrow:1}} />
                                <AdaptiveTypography><span style={{fontWeight:600}}>₹{(price??0)-savedAmount}</span></AdaptiveTypography>
                            </div>
                            </>
                        }
                        <Button
                            loading={loading}
                            value="contained"
                            onClick={() => onNext()}
                        >
                            Continue
                        </Button>
                    </div>
                    <div style={{ height: '40px' }} />
                </div>

            }

        </div>
    )
}


export default ProgramPage;