import httpClient from './index.service';
import { getUserSteps } from './users.service';

export const getQuestions = async () => {
  const res = await httpClient.get(`/questions`);
  return res.data;
};

export const getUserDetails = async () => {
  const userId = await getCurrentUserId();
  const res = await httpClient.get(`/users/${userId}`);
  return res.data;
};

export const getCurrentUserId = async () => {
  const id = localStorage.getItem('userId');
  return id;
};

export const answerCheckinQuestion = async (questionId: string, answers: any[]) => {
  const result = await httpClient.post(`/questions/save-answer`, {
    questionId: questionId,
    answers: answers,
    week: 0,
  });
  return result;
};

export const getCheckinSummaryResult = async () => {
  const result = await httpClient.get(`/questions/summary?week=0`);
  return result.data;
};
export const updateUserBasicDetails = async ({
  address,
  dob,
  gender,
  // education,
  // relationshipStatus,
  occupation,
}: {
  address: string;
  dob: string;
  gender: string;
  // education: string;
  // relationshipStatus: string;
  occupation: string;
}) => {
  const result = await httpClient.patch(`/users/self-check-info`, {
    address: address,
    dateOfBirth: dob,
    gender: gender,
    // education: education,
    // relationshipStatus: relationshipStatus,
    occupation: occupation,
  });
  return result;
};

export const markCheckinStepCompleted = async () => {

  const id = await getCurrentUserId();
  const result = await httpClient.patch(`/users/step?id=${id}`, {
    stepsCompleted: 2,
  });
  return result;
};


export const orderIntakeCall = async (appointment_id:string) => {
  const result = await httpClient.post(`/payment/intial-call/order`, {
    appointment_id:appointment_id
  });
  return result;
}

export const orderIntakeOfflineCall = async (appointment_id:string, paymentType:string) => {
  const result = await httpClient.post(`/payment/userOfflineOrder`, {
    // appointment_id:appointment_id

    'userId': localStorage.getItem('userId'),
    'paymentType':  paymentType,
    'returnUrl': import.meta.env.DEV? 'https://uat-web.mitsu.care/': window.location.href,
  });
  return result;
}

export const getCallInfo = async () => {
  const result = await httpClient.post(`/appointments/remaining-call-count`);
  return result;
}

export const getOrderStatus = async (orderId: string) => {
  const result = await httpClient.get(`/payment/order-status/${orderId}`)
  return result;
}

export const getPaymentTypes = async () => {
  const result = await httpClient.get(`/payment/getAllPaymentType`)
  return result;
}

export const orderTrial = async () => {
  const result = await httpClient.post('/payment/trial/order', {})
  return result;
}

export const orderProgram = async (coupon?:string) => {
  const result = await httpClient.post('/payment/order', {
    ...(coupon && {'coupon_id': coupon})
  })
  return result;
}

export const checkCoupon = async (couupon:string) => {
  const result = await httpClient.get(`/payment/apply-coupon/${couupon}`)
  return result
}