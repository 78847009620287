import { SERVER_CONFIG } from '../helpers/constant';
import httpClient from './index.service';

export async function getOtpConfirmation(requestBody: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}/${SERVER_CONFIG.otpSend}`, requestBody);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getOtpVerification(requestBody: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}/${SERVER_CONFIG.otpVerify}`, requestBody);
    return response;
  } catch (error: any) {
    return error;
  }
}

function getFbp(): string | null {
  const cookie = document.cookie.match(/_fbp=([^;]+)/);
  return cookie ? cookie[1] : null;
}

function getFbc(): string | null {
  const fbcCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('_fbc='))
    ?.split('=')[1];

  if (fbcCookie) {
    return fbcCookie; // Use cookie if available
  }
  
  const storedUrl = sessionStorage.getItem('source_url') || window.location.href;
  const urlParams = new URLSearchParams(new URL(storedUrl).search);
  const fbclid = urlParams.get('fbclid');

  if (fbclid) {
      return `fb.1.${Math.floor(Date.now() / 1000)}.${fbclid}`;
  }

  return null;
}
export async function trackConversion(eventName: string) {
  const sourceUrl = sessionStorage.getItem('source_url') || window.location.href;
  const fbp = getFbp();
  const fbc = getFbc();

  await fetch('https://mitsu.care/wp-json/meta/v1/track', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          event_name: eventName,
          event_source_url: sourceUrl,
          _fbp: fbp,
          _fbc: fbc,
      }),
  });
}



export async function sendUserSignupDetails(requestBody: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}/${SERVER_CONFIG.signupDetails}`, requestBody);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function signupOfflineUser(name:string, mobile:string) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}`, {
      "name": name,
      "email": `dummy${mobile}@mitsu.care`,
      "mobile": `+91${mobile}`,
      "version": "v2",
      "isOfflineUser": true
    });
    console.log({response})
    return response;
  } catch (error: any) {
    return error;
  }
}