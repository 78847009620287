import { prepareForSlot } from '@mui/base/utils';
import { Box, IconButton, Modal, TextField, useMediaQuery, useTheme } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon, TimeIcon } from '@mui/x-date-pickers';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import CloseIcon from '../../assets/icons/CloseIcon';
import { AdaptiveTypography, Button, Radio, Select, Typography } from '../../components/base';
import { CALL_MAP, MixPanelEvents, cancellationReasons } from '../../helpers/constant';
import { AppContext } from '../../helpers/hooks/AppContext';
import { CLIENT_ROUTES } from '../../router/routes';
import {
  cancelCall,
  getAvailableSlots,
  getPreviousTimeSlot,
  scheduleTimeSlot,
} from '../../services/callSchedule.service';
import { getCallInfo, getCurrentUserId, getOrderStatus, getUserDetails, orderIntakeCall } from '../../services/selfcheckin.service';
import { getUserSteps } from '../../services/users.service';
import style from './scheduleCall.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { useSearchParam } from 'react-use';
import PriceText from '../../components/common/price-text';
import { toast } from 'react-toastify';
import notify from '../../helpers/toastify-helper';
import CallPricingPage from './callPricing.page';


const ScheduleCallPage = () => {
  const { mixpanel, appState, appDispatch } = useContext(AppContext);


  const isEmailVerified = localStorage.getItem('emailVerified');
  if (!isEmailVerified) {
    return <Navigate to={`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.email_verification}`} />;
  }
  const theme = useTheme();
  const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));

  const [selectedDate, setSelectedDate] = useState<any>(null);

  const [slots, setSlots] = useState<Array<any>>([]);
  const [previousSlot, setPreviousSlot] = useState<any>(null);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [isCallCompleted, setIsCallCompleted] = useState(
    appState.stepsData?.stepsCompleted && appState.stepsData?.stepsCompleted >= 4,
  );

  const [isCallPaid, setIsCallPaid] = useState(false);



  const orderCall = async (appointment_id:string) => {
    const paymentRes = await orderIntakeCall(appointment_id);
    const paymentLink = paymentRes.data.payment_links.web;
    // console.log(paymentLink)

    try {
      mixpanel.track(MixPanelEvents.paymentStarted, {
        isWebFlow: true,
      });
    } catch (error) {
      //
    }
    window.location.replace(paymentLink);
  }

  const [searchParams, setSearchParams] = useSearchParams();
  let orderId = searchParams.get('order_id')


  const [isCorporate, setIsCorporate] = useState(false);

  useEffect(() => {
    const setCorporate = async () => {
      const userDetails = await getUserDetails();
      if (userDetails.isCorporateActive && userDetails.isUserActive) {
        setIsCorporate(true)
      }
      if (!!!(userDetails.dateOfBirth)){
        navigate(`../../${CLIENT_ROUTES.dashboard}`);
      }
    }
    setCorporate()
  })


  useEffect(() => {
    try {
      mixpanel.track(MixPanelEvents.callSetupPageView, {
        isWebFlow: true,
      });
    } catch (error) {
      //
    }
    const getSlots = async () => {
      try {
        setIsLoading(true);
        const slots = await getAvailableSlots();
        // console.log({slots})
        setIsLoading(false);
        setSlots(slots);
      } catch (error) {
        //ignore
      }
    };

    const getAlreadySelectedSlot = async () => {
      try {
        const slot = await getPreviousTimeSlot();
        setPreviousSlot(slot);
      } catch (error) {
        //ignore
      }
    };

    const getStepsCompleted = async () => {
      try {
        const userId = await getCurrentUserId();
        const response = await getUserSteps(`${userId}`);

        if (response?.data) {
          const { data } = response;
          appDispatch({
            type: 'setStepsData',
            payload: {
              stepsData: {
                stepsCompleted: data.stepsCompleted,
              },
            },
          });

          if (Number.isInteger(data.stepsCompleted) && data.stepsCompleted < 2) {
            console.log('Steps completed < 2 : ', data.stepsCompleted)
            navigate(`../../${CLIENT_ROUTES.dashboard}`);
          }
          setIsCallCompleted(data.stepsCompleted >= 4);
        }
      } catch (error) {
        //ignore 
      }
    };

    const getPaidCallInfo = async () => {
      let data = await getCallInfo();

      // console.log(data)
      setIsCallPaid(!!data.data.introCall)


      // console.log('getpaidcallinfo called', !!data.data.introCall)
      if (!orderId) {
        return
      }
      let orderData = await getOrderStatus(orderId!);
      console.log(orderData.data.status)

      switch ((orderData.data.status.toString().toLowerCase())) {

        case 'backpressed':
        case 'pending_vbv':
        case 'authorizing':
        case 'new':
          navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.payment_processing}`);
          break;
        case 'user_aborted':
        case 'authorization_failed':
        case 'authentication_failed':
        case 'api_failure':
          navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.payment_failed}`);
          break;
        case 'charged':
          break;

      }
      if (previousSlot) {
        console.log('previous slot so ending')
        return;
      }

      console.log({ isPaid: !!data.data.introCall, orderId })
      if (!!data.data.introCall && orderId) {
        let scheduled_time = JSON.parse(localStorage.getItem('selected_time') ?? '')
        let scheduled_date = JSON.parse(localStorage.getItem('selected_date') ?? '')
        await getSlots();
        if (scheduled_date && scheduled_time) {
          setSelectedDate(scheduled_date)
          setSelectedTimeSlot(scheduled_time)
          console.log({ scheduled_time, selectedTimeSlot })

          onNext(scheduled_time)
        }
      }
    }
    let futures = []
    let previous = getAlreadySelectedSlot();
    futures.push(previous)
    if (!isCallCompleted) {
      let slot = getSlots();
      futures.push(slot)
    }
    if (!appState.stepsData?.stepsCompleted) {
      let step = getStepsCompleted();
      futures.push(step)
    } else if (appState.stepsData?.stepsCompleted < 3) {
      // navigate(`../../${CLIENT_ROUTES.dashboard}`);
    }
    Promise.all(futures).finally(
      () => {
        getPaidCallInfo()
      }
    )
  }, []);

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>(null);

  let datesSet = {}

  for(let therapist of Object.keys(slots)){
    
      let therapistdates = ((slots as any)[therapist] as any)?.slots
      for (let dateslot of therapistdates) {
        let curdate = dateslot.date
        let bs = ((slots as any)[therapist] as any)?.bookedCount
        let bookedSlots = bs?.find((ob:any)=>moment(ob.date,"MM/DD/yyyy").format("MM/DD/yyyy") == moment(curdate, "MM/DD/yyyy").format("MM/DD/yyyy"))?.bookedCount
        if (dateslot?.time) {
          for(let slot of dateslot.time){
            let replace = Math.random() < 0.5;
            if(!(datesSet as any)[curdate]){
              (datesSet as any)[curdate] = {}
            }
            if((datesSet as any)[curdate][(slot as any).startTime]){
              var previous = (datesSet as any)[curdate][(slot as any).startTime]
              if(previous.isOnlineOnly && !(slot as any).isOnlineCall) {
                replace = false
              }else if (!previous.isOnlineOnly && (slot as any).isOnlineCall) {
                replace = true
              } else if (previous.bookedSlots > bookedSlots){
                replace = true
              } else if (previous.bookedSlots < bookedSlots) {
                replace = false
              }
            }else{
              replace = true
            }
  
            if(replace){
              (datesSet as any)[curdate][(slot as any).startTime] = {
                'slot':slot,
                'bookedSlots': bookedSlots,
                'isOnlineOnly': (slot as any).isOnlineCall,
                'therapist': therapist,
              }
            }
          }
        }
      }
    
  }


  const dates = Object.keys(datesSet).map((slotDate) => ({
    label: moment(slotDate, 'MM/DD/yyyy').format('DD/MM/YYYY'),
    value: slotDate,
  }));

  
  dates?.sort((a,b)=>moment(a.label, 'DD/MM/YYYY').diff(moment(b.label, 'DD/MM/YYYY')))

  if(!selectedDate && dates.length>0){
    setSelectedDate(dates[0])
  }

  
  let times = selectedDate != null
      ? Object.values((datesSet as any)[selectedDate.value])
        .map(
          (slot:any)=>(
            {
              label: `${moment(slot.slot.startTime).local().format('h:mm a')} to ${moment(slot.slot.endTime)
                .local()
                .format('h:mm a')}`,
              value: slot.slot.id,
              time: moment(slot.slot.startTime).local(),
              endtime: moment(slot.slot.endTime).local()
            }
          )
        )
      : null;
  
      times?.sort((a,b)=>a.time.diff(b.time))
  
  // console.log(selectedTimeSlot);
  // console.log(selectedDate);



  const [isTimeSlotExpired, setIsTimeSlotExpired] = useState(false);

  const [showPricingPage, setShowPricingPage] = useState(false);
  const onNext = async (timeslot = null, order = false) => {
    if (!isScheduleConfirmationOpen && !timeslot) {
      setScheduleConfirmationOpen(true)
      return;
    }
    if (!(selectedTimeSlot != null) && !timeslot) {
      // console.log('valid time not selected')
      // console.log({ selectedTimeSlot })
      return;
    }
    let newTimeSlots = await getAvailableSlots();
    var isAllowed = false;
    
    if(moment().isBefore(selectedTimeSlot.endtime)){
      isAllowed = true;
    }
    if(!isAllowed){
      setSlots(newTimeSlots);
      setSelectedDate(null)
      setSelectedTimeSlot(null)
      setScheduleConfirmationOpen(false)
      setIsTimeSlotExpired(true)
      return;
    }
    if (!isCallPaid && !timeslot && !previousSlot) {
      let data = await getCallInfo();

      setIsCallPaid(!!data.data.introCall)
      let isCallPaidNew: boolean = isCallPaid;

      try {
        isCallPaidNew = !!data.data.introCall
      } catch (e) {
        //ignore
      }

      // console.log({isCallPaidNew})
      if (!isCallPaidNew && !timeslot && !previousSlot) {
        if (!timeslot) {
          localStorage.setItem('selected_time', JSON.stringify(selectedTimeSlot))
          localStorage.setItem('selected_date', JSON.stringify(selectedDate))
        }

        try {
          (window as any).fbq('trackCustom', 'Payment_Started')
        } catch (error) {
          console.log('Cannot send custom pixel')
        }
        if(order){
          orderCall((timeslot ?? selectedTimeSlot).value);
        }else{
          setShowPricingPage(true)
          return;
        }
        console.log('call not paid yet')
        return;
      }
    }
    try {
      setIsLoading(true);
      const response = await scheduleTimeSlot((timeslot ?? selectedTimeSlot).value);
      mixpanel.track(previousSlot ? MixPanelEvents.reScheduleCall : MixPanelEvents.scheduleCall, {
        [previousSlot ? CALL_MAP.RescheduleCall : CALL_MAP.ScheduleCall]: 'Success',
        isWebFlow: true,
      });
      if (previousSlot) {

        try {
          (window as any).fbq('trackCustom', 'Call_scheduled_event')
        } catch (error) {
          console.log('Cannot send custom pixel')
        }
        navigate(`../${CLIENT_ROUTES.call_rescheduled}`);
      } else {
        try {
          (window as any).fbq('trackCustom', 'Call_scheduled_event')
        } catch (error) {
          console.log('Cannot send custom pixel')
        }
        navigate(`../${CLIENT_ROUTES.call_confirmation}`);
      }
    } finally {
      setIsLoading(false);
    }

  };
  const onEnrolClick = () => {
    navigate(`../../${CLIENT_ROUTES.dashboard}`);
    navigate(`../../${CLIENT_ROUTES.enroll}`);
  };


  const [isCancelOpen, setCancelOpen] = useState(false);

  const [isScheduleConfirmationOpen, setScheduleConfirmationOpen] = useState(false)
  const [cancellationUnavailable, setCancellationUnavailable] = useState(false)

  const therapists = [
    {
      'name': 'Dr. Mrigaya Sinha',
      'qualification': 'Ph.D. NIMHANS, Post - Doc Staunton Clinic, USA',
      'pic': '/assets/images/therapists/Mrigaya.jpg'
    }, {
      'name': 'Ms. Raksha Rajesh',
      'qualification': 'M.Phil. in Clinical Psychology',
      'pic': '/assets/images/therapists/Raksha.jpg'
    }, {
      'name': 'Ms. Vidula Sawant',
      'qualification': 'M.Phil. in Clinical Psychology',
      'pic': '/assets/images/therapists/Vidula.jpg'
    }, {
      'name': 'Ms. Bijal Shah',
      'qualification': 'M.Phil. in Clinical Psychology',
      'pic': '/assets/images/therapists/Bijal.jpg'
    },
  ]


  let canReschedule = true
  if(previousSlot?.previousStart){
    // console.log({previousSlot})
    let hoursLeft = moment(previousSlot?.previousStart).diff( moment(), 'hour')
    if(hoursLeft< 24){
      canReschedule = false
    }
  }

  if(showPricingPage){
    return <CallPricingPage selectedDate={selectedDate} selectedTime={selectedTimeSlot} onNext={()=>{
      onNext(null,true)
      // setShowPricingPage(false)
    }} />
  }


  return (
    <div className={style.scheduleCallContainer}>
      <Modal
        open={isTimeSlotExpired}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`} style={{ maxWidth: '800px' }}>
          
          <Typography variant="h3" className="mitsu-select-modal-title">
            The slot is unavailable
          </Typography>
          <div style={{ height: '32px' }} />
          <AdaptiveTypography variant="body" mobileVariant="subheading2">
          Oops! That time slot was just booked. Please choose another one.
          </AdaptiveTypography>
          <div style={{ height: '32px' }} />
          <div className={style.buttonRow}>
            < Button variant="contained-nopad" onClick={() => setIsTimeSlotExpired(false)}>
              <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                Okay
              </div>
            </Button>
          </div>
        </div>
      </Modal >
      <Modal
        open={cancellationUnavailable}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`} style={{ maxWidth: '800px' }}>
          
          <Typography variant="h3" className="mitsu-select-modal-title">
          Cancellation unavailable
          </Typography>
          <div style={{ height: '32px' }} />
          <AdaptiveTypography variant="body" mobileVariant="subheading2">
          We're sorry, but you can only cancel a session up to 24 hours before it starts
          </AdaptiveTypography>
          <div style={{ height: '32px' }} />
          <div className={style.buttonRow}>
            < Button variant="contained-nopad" onClick={() => setCancellationUnavailable(false)}>
              <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                Okay
              </div>
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={isScheduleConfirmationOpen}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`} style={{ maxWidth: '800px' }}>
          {previousSlot && !canReschedule ?
            <Typography variant="h3" className="mitsu-select-modal-title">
              Rescheduling unavailable
            </Typography> :
            <Typography variant="h3" className="mitsu-select-modal-title">
              Are you sure you are available at {selectedTimeSlot?.label?.split('to')?.[0]} on {moment(selectedDate?.value, 'M,D,YYYY')?.format('dddd, Do MMMM')}?
            </Typography>}
          <div style={{ height: '32px' }} />
          <AdaptiveTypography variant="body" mobileVariant="subheading2">
            {
              previousSlot ? canReschedule ? 'Note: You can only reschedule or cancel up to 24 hours before your call.' : "We're sorry, but you can only reschedule a session up to 24 hours before it starts." : 'Note: You can only reschedule or cancel up to 24 hours before your call.'
            }
          </AdaptiveTypography>
          <div style={{ height: '32px' }} />
          <div className={style.buttonRow}>
            {(!previousSlot || canReschedule) && <Button
              onClick={() => onNext()}
              loading={isLoading}
              variant={'outlined-nopad'}
              className={style.dialogSecondaryButton}
              style={{ height: 'unset', width: 'unset' }}
            >
              <div className={style.dialogButton}>Yes, schedule</div>
            </Button>}
            {(!previousSlot || canReschedule) && <span style={{ width: '24px' }} />}
            {< Button variant="contained-nopad" onClick={() => setScheduleConfirmationOpen(false)}>
              <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                {(!previousSlot || canReschedule) ? 'No' : 'Okay'}
              </div>
            </Button>}
          </div>
        </div>
      </Modal >
      <CancelReasonDialog isOpen={isCancelOpen} onClose={() => setCancelOpen(false)} />
      <div className={style.scheduleCallPageCenter}>
        <div className={style.ScheduleCallPage}>
          <div className={`${style.scheduleCallHeadContainer} ${isTwoColumn ? '' : style.alignJustify}`}>
            <AdaptiveTypography variant="h1" mobileVariant="h3">
              Set up your intake call
            </AdaptiveTypography>
            <div style={{ height: '8px' }} />
            <AdaptiveTypography variant="subheading1" mobileVariant="subheading2">
              Please select a 30-min time slot for an introductory call with a Mitsu therapist.
              <br />
              <br />
              You can find more details and FAQs related to the call by <Link to={`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.call_explainer}`} className={style.clickhere}>clicking here</Link>
            </AdaptiveTypography>
          </div>
          <div style={{ height: '32px' }} />

          <div style={{ height: '32px' }} />

          {previousSlot && (
            <div className={`${style.previousCallBody} ${isTwoColumn ? '' : style.alignJustify}`}>
              <Typography variant="h2">Your scheduled call details</Typography>
              <div className={style.callTitleSpacing} />
              <Typography variant="label2">{isCallCompleted ? "Your intake call was completed on:" : ""}</Typography>
              <div className={style.callTitleHeaderSpacing} />
              <div className={`${style.previousSlowRow} ${style.col_2}`}>
                <div className={style.previousItemContainer}>
                  <CalendarIcon fontSize="small" /> <Typography variant="label"> Selected date </Typography>
                  <div />{' '}
                  <Typography variant="subheading3">
                    {moment(previousSlot.previousStart).local().format('DD/MM/YYYY')}
                  </Typography>
                </div>
                <div className={style.previousItemContainer}>
                  <TimeIcon fontSize="small" />
                  <Typography variant="label"> Selected time slot </Typography>
                  <div />{' '}
                  <Typography variant="subheading3">{`${moment(previousSlot.previousStart)
                    .local()
                    .format('h:mm a')} to ${moment(previousSlot.previousEnd).local().format('h:mm a')}`}</Typography>
                </div>
              </div>
              <div className={style.sectionGroupGap} />
            </div>
          )}

          {!isCallCompleted && (
            <div className={`${style.scheduleCallBody} ${isTwoColumn ? '' : style.alignJustify}`}>
              <Typography variant="h2">{previousSlot ? 'Reschedule Call' : 'Select a date & time'}</Typography>
              <div className={style.callTitleSpacing} />
              <Typography variant="label2">{previousSlot ?
                <span>
                  Need to make a change? We understand. You can select a new date and time to reschedule your session.
                  <br/><br/>
Please Note:<br/><br/>
1. Calls can be rescheduled up to 24 hours before the scheduled time to avoid charges.<br/><br/>
2. For more details, refer to our Refunds and Cancellation Policy in the Terms & Conditions section.</span> :
                ''}</Typography>
              <div className={style.callTitleHeaderSpacing} />
              <div className={`${style.slotSelectorRow} ${style.col_2}`}>
                <DateSelector
                  dates={dates}
                  selectedDate={selectedDate}
                  onSelectDate={(date)=>{setSelectedDate(date)}}
                  times={times}
                  selectedTime={selectedTimeSlot}
                  onSelectTime={(ev)=>{
                    setSelectedTimeSlot(ev)
                  }}
                />
              </div>
            </div>
          )}

          <div className={style.spacer} />
          <div style={{ height: '40px' }} />
        </div>
      </div>
      {
        isCallCompleted ? (
          <div className={style.buttonRow}>
            <Button variant="contained" onClick={onEnrolClick}>
              Enrol in Program
            </Button>
          </div>
        ) : previousSlot ? (
          <div className={style.buttonRow}>
             <Button
              onClick={() => {
                if(canReschedule){
                  setCancelOpen(true);
                }else{
                  setCancellationUnavailable(true)
                }
              }}
              variant={isTwoColumn ? 'outlined-nopad' : 'text-noshadow'}
              className={`${isTwoColumn ? '' : style.mobileButton}`}
            >
              <div className={style.buttonContent}>Cancel call</div>
            </Button>

           
              <span style={{ width: '24px' }} />
            <Button
              variant='contained-nopad'
              loading={isLoading}
              disabled={!(selectedTimeSlot != null)}
              onClick={() => onNext()}
            >
              <div className={style.buttonContent} style={{ height: 'unset', width: 'unset' }}>
                Reschedule call
              </div>
            </Button>
          </div>
        ) : (
          <>
            {/* {
              !isCorporate && isCallPaid && <div className={style.paidInfo}>
                Your intro call payment has been received successfully
              </div>
            } */}
            <div className={style.buttonRow}>
              <Button
                loading={isLoading}
                value="contained"
                disabled={!(selectedTimeSlot != null)}
                onClick={() => onNext()}
              >
                {!isCallPaid ? <>Schedule call for ₹<PriceText priceKey="intialCall"/></> : 'Schedule call'}
              </Button>
            </div>
          </>
        )
      }
    </div >
  );
};
const BoxSlot = prepareForSlot(Box);

const CancelReasonDialog = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [confirm, setConfirm] = useState(false);

  const [optionSelected, setOptionSelected] = useState<any>(null);

  const options = cancellationReasons;

  const [otherReason, setOtherReason] = useState('');

  const handleClose = () => {
    setConfirm(false);
    setOptionSelected(null);
    onClose();
  };
  const { mixpanel } = useContext(AppContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const isCancelCallValid = optionSelected && (optionSelected.value != options.at(-1)?.value || otherReason.trim());
  const onCancel = async (reason: string) => {
    try {
      setLoading(true);
      try {
        const response = await cancelCall(reason);

        mixpanel.track(MixPanelEvents.cancelCall, {
          [MixPanelEvents.cancelCall]: 'Success',
          isWebFlow: true,
        });
        navigate(`../${CLIENT_ROUTES.call_cancelled}`);
      } catch (error) {
        notify({message:'Cancellation Failed', severity:'error'})
      }
    } finally {
      setLoading(false);
    }
  };
  const onCancelCall = () => {
    onCancel(optionSelected.value == options.at(-1)?.value ? otherReason : optionSelected.label);
  };

  if (confirm) {
    return (
      <Modal
        open={isOpen}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={'mitsu-select-modal-container'}>
          <div className="mitsu-select-modal-heading">
            <Typography variant="h3" className="mitsu-select-modal-title">
              Select a reason to cancel
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <hr />
          <div className="mitsu-select-modal-body">
            {options?.map((option) => {
              if ('label' in option && 'value' in option) {
                return (
                  <div
                    key={option.value}
                    className="mitsu-select-modal-option-row"
                    onClick={() => setOptionSelected(option)}
                    aria-hidden
                  >
                    <Typography variant="label">{option.label}</Typography>
                    <div className="mitsu-select-modal-option-spacer" />
                    {<Radio checked={optionSelected?.value === option?.value} value={option.value} />}
                  </div>
                );
              } else {
                <></>;
              }
            })}
            {optionSelected?.value == options.at(-1)?.value && (
              <TextField
                value={otherReason}
                onChange={(val) => setOtherReason(val.target.value)}
                variant="standard"
                placeholder="Specify reason"
                style={{ marginTop: '5px' }}
              />
            )}
          </div>
          <Button
            loading={loading}
            disabled={!isCancelCallValid}
            onClick={onCancelCall}
            className="mitsu-select-modal-option-cta"
          >
            Cancel call
          </Button>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        open={isOpen}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`}>
          <Typography variant="h3" className="mitsu-select-modal-title">
            Are you sure you want to cancel your call with the therapist?
          </Typography>
          <div style={{ height: '32px' }} />
          <div className={style.buttonRow}>
            <Button
              onClick={() => {
                setConfirm(true);
              }}
              variant={'outlined-nopad'}
              className={style.dialogSecondaryButton}
              style={{ height: 'unset', width: 'unset' }}
            >
              <div className={style.dialogButton}>Yes, cancel</div>
            </Button>
            <span style={{ width: '24px' }} />
            <Button variant="contained-nopad" onClick={onClose}>
              <div className={style.dialogButton} style={{ height: 'unset', width: 'unset' }}>
                No
              </div>
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
};


const DateSelector = ({dates,selectedDate,onSelectDate,times,selectedTime,onSelectTime}:{dates:Array<{label:string, value:string}>,
  selectedDate: string | null,
  onSelectDate: (date:any)=>void,
  times: Array<{label:string,value:any, time: moment.Moment}> | null,
  selectedTime: any,
  onSelectTime: (time:any)=>void,

}) => {

  const [shift, setShift] = useState(0)
  const days = Array.from({ length: 7 }, (_, i) => moment().add(shift+ i, 'days'));

  return (
    <div style={{width:'100%'}}>
      <Typography variant='p' className={style.pickertitle}>Please pick a date and time you want to schedule your call to</Typography>

      <div className={style.dateMainSelector}>

        <IconButton sx={{border:1, borderRadius:'8px', padding:'4px'}} 
          onClick={()=>{
            setShift(shift-7)
          }}
        > <ArrowLeftIcon/> </IconButton>

        <div className={style.dateNumSelectorContainer}>

          <div className={style.dateDoWSelectorBoundary}>
            {
              days.map(
                (day)=>(
                  <Typography variant='label' key={day.format('ddd')} className={style.dayOfWeek}>
                    {day.format('ddd')}
                  </Typography>
                )
              )
            }
          </div>

          <div className={style.dateNumSelectorBoundary}
          >
            {
              days.map(
                (day)=>{
                  
                  console.log(day.format('DD/MM/yyyy'))
                  var isAvailable =  dates.find((e)=>e.label==day.format('DD/MM/yyyy'))
                  var isSelected = (selectedDate as any)?.label == day.format('DD/MM/yyyy');

                  return (
                    <Typography variant='label' key={day.format('ddd/MM')} className={`${style.dateNum} ${isAvailable? isSelected? style.selected : style.available:style.unavailable}`}

                      onClick={
                        ()=>{
                          if(isAvailable){
                            onSelectDate(isAvailable)
                          }
                        }
                      }
                    >
                      {day.format('D')}
                      <br/>
                      {day.format('MMM')}
                    </Typography>
                  )
                }
              )
            }
          </div>
        </div>
        
        <IconButton sx={{border:1, borderRadius:'8px', padding:'4px'}}
          onClick={()=>{
            setShift(shift+7)
          }}
        > <ArrowRightIcon/> </IconButton>

      </div>

      <div className={style.slotArea}>
        {
          times?.map(
            (e)=>{
              var isSelected = !e.time.diff( selectedTime?.time ?? moment());
              return <div key={e.label} onClick={()=>{onSelectTime(e)}} className={`${style.timechip} ${isSelected? style.selectedSlot:style.unselectedSlot}`}>
                  <Typography className={`${isSelected? style.selectedSlot:style.unselectedSlot}`}>{e.time.format('h:mm A')}</Typography>
                </div>
            }
          )
        }
      </div>
    </div>
  )
}

export default ScheduleCallPage;
